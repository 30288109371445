export enum ApiSortOrderEnum {
    Unknown = 0,
    Asc = 1,
    Desc = 2
  }
export class ApiPagedInputBase {
    currentPage: number = 1;
    pageSize: number = 15;
    sortOrder: ApiSortOrderEnum = 1;
    sortColumn: string = '';
}

export class PagedResult<T> {
    currentPage: number = 0;
    pageCount: number = 0;
    pageSize: number = 0;
    rowCount: number = 0;
    firstRowOnPage: number = 0;
    lastRowOnPage: number = 0;
    results: T[] = new Array<T>();    
}

export class Pagination {
    currentPage: number = 0;
    pageCount: number = 0;
    pageSize: number = 0;
    rowCount: number = 0;
    firstRowOnPage: number = 0;
    lastRowOnPage: number = 0;  
}


