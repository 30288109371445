import { Marker, useMap } from "react-leaflet";
import { Dispatch, SetStateAction } from "react";
import MowerLabelIcon from "./MowerLabelIcon";

export interface MapMarkerProps {
  latitude: number;
  longitude: number;
  iconLetter: string;
  color: string;
}

interface Props {
  markers: Array<MapMarkerProps>;
  showLabels: boolean;
  SetHighlightedLocationIndex: Dispatch<SetStateAction<number>>;
  callback: (
    numberOfMowers: number,
    index: number,
    SetHighlightedLocationIndex: Dispatch<SetStateAction<number>>
  ) => void;
}

const MapMarkers = (props: Props) => {
  const map = useMap();
  return (
    <>
      {props.markers && props.markers.length > 0 ? (
        props.markers.map((marker: MapMarkerProps, index: number) => {
          if (marker.latitude !== 0 && marker.longitude !== 0) {
            return (
              <Marker
                key={index}
                icon={MowerLabelIcon(
                  props.showLabels ? marker.iconLetter : "",
                  marker.color
                )}
                eventHandlers={{
                  click: () => {
                    map.setView([marker.latitude, marker.longitude], 20);
                    if (props.SetHighlightedLocationIndex) {
                      props.SetHighlightedLocationIndex(index);
                    }
                    if (props.callback) {
                      props.callback(
                        props.markers.length,
                        index,
                        props.SetHighlightedLocationIndex
                      );
                    }
                  },
                }}
                position={{ lat: marker.latitude, lng: marker.longitude }}
              ></Marker>
            );
          }
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default MapMarkers;
