import { useAuth0 } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Divider, Icon, Label } from "semantic-ui-react";
import { useDocumentTitle } from "../../app/common/page-title";
import {
  CLAIM_OSO_APP_COMPANY_ID,
  CLAIM_OSO_APP_USER_ID,
  CLAIM_OSO_APP_IS_SUPER_ADMIN,
  LOCAL_STORAGE_CUSTOM_DATE,
} from "../../types/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const CustomDateRange = () => {
  const { mowerId } = useParams();

  const { isAuthenticated, isLoading, user } = useAuth0();
  const dateFormat: string = "YYYY-MM-DD";
  const [fromDate, setFromDate] = useState(moment().toDate());
  const [toDate, setToDate] = useState(moment().add(7, "days").toDate());
  const isMobile = window.innerWidth <= 768;
  const navigate = useNavigate();

  useDocumentTitle("Select Custom Date Range");

  useEffect(() => {
    if (isAuthenticated) {
      if (
        user![CLAIM_OSO_APP_USER_ID] === undefined ||
        user![CLAIM_OSO_APP_COMPANY_ID] === undefined ||
        user![CLAIM_OSO_APP_IS_SUPER_ADMIN] === undefined ||
        user![CLAIM_OSO_APP_IS_SUPER_ADMIN] === false
      ) {
        navigate("/");
        return;
      }
    }
  });

  const backToMowerPage = () => {
    if (moment(fromDate) <= moment(toDate)) {
      localStorage.setItem(
        LOCAL_STORAGE_CUSTOM_DATE,
        JSON.stringify({ fromDate: fromDate, toDate: toDate })
      );
    } else {
      localStorage.removeItem(LOCAL_STORAGE_CUSTOM_DATE);
    }
    navigate("/mower-activity/" + mowerId);
  };

  const RenderHeading = () => {
    return isLoading || !isAuthenticated ? (
      <></>
    ) : (
      <>
        <div className="page-title">
          <Icon
            size="small"
            color="black"
            link
            name="arrow left"
            onClick={() => {
              backToMowerPage();
            }}
            className="no-border"
          />
          Select Custom Date Range
        </div>
        <br />
        <Divider section />
      </>
    );
  };

  const handleDateRangeChange = (dates: any) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  //https://reactdatepicker.com/#example-date-range-for-one-datepicker
  const RenderCustomDateRange = () => {
    return (
      <>
        <Label basic className="date-label">
          {moment(fromDate).format(dateFormat)}
        </Label>
        <Label basic className="date-label">
          {moment(toDate).format(dateFormat)}
        </Label>
        <br />
        <br />
        <DatePicker
          selected={fromDate}
          onChange={handleDateRangeChange}
          startDate={fromDate}
          endDate={toDate}
          selectsRange
          inline
        />
      </>
    );
  };

  const DesktopCustomDateRangeView = () => {
    return (
      <>
        {isLoading || !isAuthenticated ? (
          <></>
        ) : (
          <>
            <div style={{ paddingTop: "6px" }}></div>
            <div
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
                maxWidth: "400px",
              }}
            >
              <RenderHeading />
              <br />
              <RenderCustomDateRange />
            </div>
          </>
        )}
      </>
    );
  };

  const MobileCustomDateRangeView = () => {
    return (
      <>
        {isLoading || !isAuthenticated ? (
          <></>
        ) : (
          <>
            <div style={{ paddingTop: "6px" }}></div>

            <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <RenderHeading />
              <br />
              <RenderCustomDateRange />
            </div>
          </>
        )}
      </>
    );
  };

  const MowerView = () => {
    if (isMobile) {
      return <MobileCustomDateRangeView />;
    }
    return <DesktopCustomDateRangeView />;
  };

  return <MowerView />;
};

export default observer(CustomDateRange);
