import { MowerView } from "./mower-view";

export interface MowerListQryInput {
  customerId: string;
}

export class MowerListQryInput implements MowerListQryInput {
  customerId: string;
  constructor(customerId: string) {
    this.customerId = customerId;
  }
}

export interface MowerListQryOutput {
  mowerList: MowerView[];
}

export class MowerListQryOutput implements MowerListQryOutput {
  constructor(init?: MowerListQryOutput) {
    if (init !== undefined) {
      Object.assign(this, init);
    }
  }
}
