import { LOCAL_STORAGE_SEL_CUSTOMER, LOCAL_STORAGE_SEL_CUSTOMER_NAME } from "../types/constants";

export const formatPhoneNumber = (value: string) => {
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

export function setCustomerId(customerId: string){
  localStorage.setItem(
    LOCAL_STORAGE_SEL_CUSTOMER,
    JSON.stringify({ customerId: customerId })
  );
}

export function setCustomerName(customerName: string){
  localStorage.setItem(
    LOCAL_STORAGE_SEL_CUSTOMER_NAME,
    JSON.stringify({ customerName: customerName })
  );
}

export function getCustomerName() {
  let selCustomer = "";
  if (localStorage.getItem(LOCAL_STORAGE_SEL_CUSTOMER_NAME)) {
    let json = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_SEL_CUSTOMER_NAME) ||
      '{customerName: ""}'
    );
    selCustomer = json["customerName"];
  }
  return selCustomer;
}

export function getCustomerId(customerIdFromToken: string) {
  if (!customerIdFromToken) return "0";

  if (localStorage.getItem(LOCAL_STORAGE_SEL_CUSTOMER)) {
    let json = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_SEL_CUSTOMER) || "{'customerId' : 0}"
    );
    customerIdFromToken = json["customerId"];
  }
  //let customerId: number = parseInt(customerIdFromToken);

  return customerIdFromToken;
}

export function toHoursAndMinutes(totalMin: string) {
  if (Number.isNaN(totalMin)) {
    return "00:00";
  }

  const totalM = Number(totalMin);
  const hours = Math.floor(totalM / 60);
  const minutes = totalM % 60;
  let result: string;
  result = `${hours.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${minutes.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;

  if (Number.isNaN(result)) {
    return "00:00";
  }
  return result;
}

export function toHoursOrMinutes(totalMin: string) {
  if (Number.isNaN(totalMin)) {
    return "";
  }

  const totalM = Number(totalMin);

  if (totalM < 60) {
    return totalMin.toString() + " min";
  }
  const hours = Math.floor(totalM / 60);
  const minutes = totalM % 60;
  let result: string;
  if (minutes > 0) {
    //result = hours.toString() + ":" + minutes.toString() + " hrs";
    if (hours == 1) {
      result = `${hours.toString()} hr ${minutes.toString()} min`;
    } else {
      result = `${hours.toString()} hrs ${minutes.toString()} min`;
    }
  } else {
    result = hours.toString() + " hrs";
  }

  if (Number.isNaN(result)) {
    return "";
  }
  return result;
}

export function groupBy<K, V>(array: V[], grouper: (item: V) => K) {
  return array?.reduce((store, item) => {
    var key = grouper(item);

    if (!key) {
      return new Map<K, V[]>();
    }

    if (!store.has(key)) {
      store.set(key, [item]);
    } else {
      store?.get(key)?.push(item);
    }
    return store;
  }, new Map<K, V[]>());
}
