import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDocumentTitle } from "../app/common/page-title";
import { Button, Divider, Icon, Label } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import {
  CLAIM_OSO_APP_COMPANY_NAME, LOCAL_STORAGE_SEL_CUSTOMER, LOCAL_STORAGE_SEL_CUSTOMER_NAME
} from "../types/constants";

const ProfilePage = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, user, logout } =
    useAuth0();
  const [token, setToken] = useState("");

  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;

  const navigate = useNavigate();

  useEffect(() => {
    getAccessTokenSilently().then((r) => setToken(r));
  }, [getAccessTokenSilently, setToken]);

  useDocumentTitle("Profile");

  const handleLogout = () => {
    if (localStorage.getItem(LOCAL_STORAGE_SEL_CUSTOMER_NAME)) {
      localStorage.removeItem(LOCAL_STORAGE_SEL_CUSTOMER_NAME);
    }
    if (localStorage.getItem(LOCAL_STORAGE_SEL_CUSTOMER)) {
      localStorage.removeItem(LOCAL_STORAGE_SEL_CUSTOMER);
    }
    logout({ returnTo: window.location.origin });
  };

  return (
    <>
      {isLoading || !isAuthenticated ? (
        <></>
      ) : (
        <>
          <div style={{ paddingTop: "6px" }}></div>
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              maxWidth: "400px",
            }}
          >
            <div className="page-title">
              <Icon
                size="small"
                color="black"
                link
                name="arrow left"
                onClick={() => navigate("/")}
                className="no-border"
              />
              &nbsp;Profile
            </div>
            <br />
            <Divider section />
            <br />
            <div style={{ textAlign: "center" }}>
              <Label circular size="huge">
                {(user?.nickname ?? user?.name)?.charAt(0)}
              </Label>
            </div>
            <br />
            <div className="profile-name">{user?.nickname ?? user?.name}</div>
            <div className="profile-comp-name">
              {user![CLAIM_OSO_APP_COMPANY_NAME]}
            </div>
            <br />
            <br />
            <div className="profile-label">Email</div>
            <div className="profile-value">{user?.email}</div>
            <br />
            <div className="profile-label">Password</div>
            <div className="profile-value">**********</div> <br />
            <div className="profile-value">
              <a href="#">Change Password</a>
            </div>
            <br />
            <br />
            <Button fluid className="profile-log-out" onClick={handleLogout}>
              LOG OUT
            </Button>
            <br />
            <a href="https://www.osoelectric.com/contact/" target="n">
              Oso Electric Equipment - Terms and Conditions
            </a>
            <br />
            <br />
          </div>
        </>
      )}
    </>
  );
};

export default ProfilePage;
