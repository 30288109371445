export const CLAIM_OSO_APP_USER_ID: string = "oso_app_user_id";
export const CLAIM_OSO_APP_COMPANY_ID: string = "oso_app_customer_id";
export const CLAIM_OSO_APP_COMPANY_NAME: string = "oso_app_customer_name";
export const CLAIM_OSO_APP_IS_SUPER_ADMIN: string = "oso_app_is_super_admin";
export const LOCAL_STORAGE_SEL_CUSTOMER = "selected-customer";
export const LOCAL_STORAGE_SEL_CUSTOMER_NAME = "selected-customer-name";
export const LOCAL_STORAGE_SEL_MOWER = "selected-mower";
export const LOCAL_STORAGE_CUSTOM_DATE = "custom-date-range";
export const DEFAULT_MAP_LATITUDE = 32.92456;
export const DEFAULT_MAP_LONGITUDE = -97.00599;
export const AUTO_REFRESH_INTERVAL_HOME_PAGE = 300000;
export const LOCAL_STORAGE_HOME_PAGE_MAP_BOUNDS = "home_page_bounds";
export const LOCAL_STORAGE_DEFAULT_MAP_BOUNDS = "is_default_page_bound";