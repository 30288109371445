import { UserFormValues } from "../types/registration/user";
import axios from "axios";

const getCurrentUser = async (): Promise<UserFormValues | null> => {
  const response = await axios.get("user/current-user-info");
  return response.data;
};

const userService = {
  getCurrentUser,
};

export default userService;
