import {
  ApiPagedInputBase,
  ApiSortOrderEnum,
  PagedResult,
  Pagination,
} from "../common/paged-result-model";
import { Moment } from "moment/moment";
import { MowerActivity } from "./mower-activity";

export class MowerActivityQryInput extends ApiPagedInputBase {
  customerId: string = "0";
  mowerId: string = "0";
  fromDate: Moment;
  toDate: Moment;
  constructor(
    customerId: string,
    mowerId: string,
    fromDate: Moment,
    toDate: Moment,
    currentPage: number,
    sortOrder: ApiSortOrderEnum
  ) {
    super();
    this.customerId = customerId;
    this.mowerId = mowerId;
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.pageSize = parseInt(process.env.REACT_APP_RESULT_PAGE_SIZE ?? "15");
    this.currentPage = currentPage;
    this.sortOrder = sortOrder;
  }
}

export interface MowerActivityQryOutput {
  mowerId: string;
  mowerName: string;
  mowerTitle: string;
  totalHrsPowerOn: number;
  totalLocationsVisited: number;
  totalHrsMowing: number;
  serialNumber: string;
  pagination: Pagination;
  //mowerActivities: PagedResult<MowerActivity>;
  mowerActivities: MowerActivity[];
}

export class MowerActivityQryOutput implements MowerActivityQryOutput {
  constructor(init?: MowerActivityQryOutput) {
    if (init !== undefined) {
      Object.assign(this, init);
    }
  }
}
