import { createContext, useContext } from "react";
import CommonStore from "./common-store";
import CustomerStore from "./customer-store";
import MowerStore from "./mower-store";
import UserStore from "./user-store";

interface Store {
  commonStore: CommonStore;
  userStore: UserStore;
  mowerStore: MowerStore;
  customerStore: CustomerStore;
}

export const store: Store = {
  commonStore: new CommonStore(),
  userStore: new UserStore(),
  mowerStore: new MowerStore(),
  customerStore: new CustomerStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
