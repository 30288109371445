import { Button, Dropdown, Grid, GridColumn, Menu, Modal, ModalActions, ModalContent, ModalHeader } from "semantic-ui-react";
import { MowerRegistration } from "../../types/registration/mowerRegistration";
import { SyntheticEvent, useState } from "react";

export const MowerReview = (props: any) => {
    const [open, setOpen] = useState(false);
    const [serialNumber, setSerialNumber] = useState("");
    const size = "tiny";
    const onDelete = (event: SyntheticEvent, data: any) => {
        var mowers = props?.mowers?.filter((mower: MowerRegistration, index: number) => index === data.value);
        if(mowers.length == 1){
            setSerialNumber(data.value);
            setOpen(true);
        }
    }

    const onEdit = (event: SyntheticEvent, data: any) => {
        var mowers = props?.mowers?.filter((mower: MowerRegistration, index: number) => index === data.value);
        if(mowers.length == 1){
            props.onEditMower(data.value);
        }
    }

    const onDeleteMower = () =>{
        setOpen(false);
        props.onDeleteMower(serialNumber);
    }

    return (<>
        <div className="ui cards">
            <div className="card" style={{ width: "100%", borderRadius: "10px" }}>
                <div className="oso-form">
                    <Grid columns={2}>
                        <GridColumn style={{ width: "20%", marginTop: "30px" }}></GridColumn>
                        <GridColumn style={{ width: "80%", marginTop: "30px" }}>
                            <Grid columns={4} className="mower-details-grid-header">
                                <GridColumn>
                                    Mower Name
                                </GridColumn>
                                <GridColumn >
                                    Product Name
                                </GridColumn>
                                <GridColumn>
                                    Serial Number
                                </GridColumn>
                                <GridColumn>

                                </GridColumn>
                            </Grid>
                        </GridColumn>
                    </Grid>
                    {props?.mowers?.map((mower: MowerRegistration, index: number) => {
                        return (<>
                            <Grid columns={2}>
                                <GridColumn style={{ width: "20%" }}>
                                    <img className="mower-review-image" src="/assets/images/mower.png" />
                                </GridColumn>
                                <GridColumn style={{ width: "80%" }}>

                                    <Grid columns={4} className="mower-details-row">
                                        <GridColumn style={{ marginTop: "20px" }}>
                                            {mower.mowerName}
                                        </GridColumn>
                                        <GridColumn style={{ marginTop: "20px" }}>
                                            21 Inch Comercial Mower
                                        </GridColumn>
                                        <GridColumn style={{ marginTop: "20px" }}>
                                            {mower.serialNumber}
                                        </GridColumn>
                                        <GridColumn style={{ marginTop: "10px" }}>
                                            <Menu compact style={{ borderRadius: "100px" }}>
                                                <Dropdown
                                                    text="Action"
                                                    item
                                                >
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            text="Edit"
                                                            value={index}
                                                            onClick={onEdit}
                                                        />
                                                        <Dropdown.Item
                                                            text="Remove"
                                                            value={index}
                                                            onClick={onDelete}
                                                        />
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Menu>
                                        </GridColumn>
                                    </Grid>
                                </GridColumn>
                            </Grid></>)

                    })}


                </div>
            </div>
        </div>
        <Modal
            size={size}
            open={open}
            onClose={() => setOpen(false)}
        >
            <ModalHeader>
                <div className="header">Remove Mower</div>
            </ModalHeader>
            <ModalContent>
                <p>Are you sure you want to remove this mower?</p>
            </ModalContent>
            <ModalActions>
                <div className="vertical-center">
                    <Button className="black" onClick={onDeleteMower}>Remove</Button>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                </div>
            </ModalActions>
        </Modal>
    </>);
}