import { OsoUser } from "./user";

export interface customerRegistration {
    customerId: string;
    customerName: string;
    primaryUser : OsoUser;
    secondaryUser : OsoUser;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode:string;  
  }
  
  export class customerRegistration implements customerRegistration {
    constructor(init?: customerRegistration) {
      if (init !== undefined) {
        Object.assign(this, init);
      } else {
        this.customerName = "";
        this.primaryUser = new OsoUser();
        this.secondaryUser = new OsoUser();
        this.address1 = "";
        this.address2 = "";
        this.city = "";
        this.state = "";
        this.postalCode = ""
      }
    }
  }