import React, { useEffect, useState } from "react";
import { useDocumentTitle } from "../../app/common/page-title";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { CLAIM_OSO_APP_COMPANY_ID, CLAIM_OSO_APP_USER_ID } from "../../types/constants";
import { observer } from "mobx-react-lite";
import AddMower from "../mower-registration/AddMower";
import { getCustomerId } from "../../shared/utils";

const AddMowerPage = () => {
    useDocumentTitle("Add Mower");
    const { isAuthenticated, isLoading, getAccessTokenSilently, user } =
        useAuth0();
    const navigate = useNavigate();
    const [customerId, setCustomerId] = useState("");
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }

        if (
            user![CLAIM_OSO_APP_USER_ID] === undefined ||
            user![CLAIM_OSO_APP_COMPANY_ID] === undefined
        ) {
            navigate("/");
            return;
        }
        setCustomerId(getCustomerId(user![CLAIM_OSO_APP_COMPANY_ID]));
    }, []);

    const onBackClick =(name: any) =>{
      navigate("/");
    }

    return (
        <>
          {!isAuthenticated ? (
            <></>
          ) : (
            <>
              <div style={{ backgroundColor: "#f5f5f5" }}>
                <div className="registration-top-card">
                  <p>
                  Add Your Mowers
                  </p>
                  
                </div>
                <div style={{ padding: "30px" }}>
                  <br/>
                  <AddMower 
                    customerId={customerId} 
                    isMowerAdd={true}
                  />
                </div>
                
              </div>

            </>
          )}
        </>
      );

}

export default observer(AddMowerPage);