import React, { useEffect, useState } from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import {
  Menu,
  Header,
  Divider,
  Button,
  Segment,
  List,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import {
  CLAIM_OSO_APP_IS_SUPER_ADMIN
} from "../../types/constants";
import { OsoUser } from "../../types/registration/user";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { getCurrentUser as getUser } from "../../store/user/userSlice";
import { Customer } from "../../types/customer/customer";
import { getCustomerName } from "../../shared/utils";

const NavBar = (props: any) => {
  const { isAuthenticated, isLoading, user, getAccessTokenSilently } =
    useAuth0();
  const [osoUser, setOsoUser] = useState<OsoUser>(new OsoUser());

  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.user);
  const [customerName, setCustomerName] = useState<string>("");

  useEffect(() => {
    if (selector.isSuccess && selector.user) {
      setOsoUser(selector.user);
    } else {
      dispatch(getUser());
    }
  }, [selector.isSuccess]);

  useEffect(() => {
    setCorrectCustomerName();
  }, [osoUser]);

  const setCorrectCustomerName = () => {
    let custName = getCustomerName();

    if(!custName && selector.isSuccess && selector.user) {
      custName = selector.user.customerName;
    }
    setCustomerName(custName);
  };

  const handleMenuToggle = (e: any, menuOpen: boolean) => {
    if (!menuOpen) {
      props.callOnOpen();
    } else {
      props.callOnClose();
    }
  };

  const AdminNavItem = (user: User) => {
    if (
      user?.user![CLAIM_OSO_APP_IS_SUPER_ADMIN] === undefined ||
      user?.user![CLAIM_OSO_APP_IS_SUPER_ADMIN] === false
    ) {
      return <></>;
    } else {
      return (
        <List.Item style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <Link to={`/customers`} className="menu-item">
            Customers
          </Link>
        </List.Item>
      );
    }
  };

  //https://negomi.github.io/react-burger-menu/
  return (
    <>
      {isLoading || !isAuthenticated ? (
        <></>
      ) : (
        <>
          <Menu borderless secondary>
            <Button
              basic
              icon={props.menuOpen ? "close" : "align justify"}
              onClick={(e) => {
                handleMenuToggle(e, props.menuOpen);
              }}
              className="no-border"
            />
            <Menu.Item
              position="left"
              style={{ marginLeft: "0px", paddingLeft: "5px"}}
              className="white-background"
            >
              <Header as="h2" className="page-title customer-name">
                {customerName}
              </Header>
            </Menu.Item>
          </Menu>
          <Divider section />

          {props.menuOpen === false ? (
            <></>
          ) : (
            <Segment
              style={{
                border: "1px solid white",
                boxShadow: "0 0 0 0",
                display: props.menuOpen ? "" : "none",
              }}
            >
              <List size="huge">
                <List.Item style={{ paddingTop: "0px", paddingBottom: "10px" }}>
                  <Link to="/" className="menu-item">
                    Home
                  </Link>
                </List.Item>
                {selector.user?.isSubscribed ? (
                  <List.Item
                  style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  <Link to="/mowers" className="menu-item">
                    Mowers
                  </Link>
                </List.Item>
                ) : (<></>)
                }
                
                <List.Item
                  style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  <Link to={`/profile`} className="menu-item">
                    Profile
                  </Link>
                </List.Item>
                <AdminNavItem user={user} />
              </List>
            </Segment>
          )}
        </>
      )}
    </>
  );
};

export default NavBar;
