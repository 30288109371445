import L from "leaflet";

const MowerLabelIcon = (label: string, activityColor: string) => {
  return L.divIcon({
    className: "",
    iconAnchor: [12, 25],
    bgPos: [-6, 0],
    popupAnchor: [0, -15],
    iconSize: [25, 41],
    html:
      `<span class="ui circular label ` +
      activityColor?.trim().toLowerCase() +
      `">` +
      label +
      `</span>`,
  });
};

export default MowerLabelIcon;
