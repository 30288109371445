import { useAuth0 } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { List, Label, Button, Divider, Icon, Input, Checkbox } from "semantic-ui-react";
import { logger } from "../../app/common/logger";
import { useDocumentTitle } from "../../app/common/page-title";
import {
  CLAIM_OSO_APP_COMPANY_ID,
  CLAIM_OSO_APP_USER_ID,
  CLAIM_OSO_APP_IS_SUPER_ADMIN,
} from "../../types/constants";
import { Customer, CustomerListQryOutput } from "../../types/customer/customer";
import { useStore } from "../../app/stores/store";
import { toast } from "react-toastify";
import { OsoUser } from "../../types/registration/user";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { getCurrentUser as getUser } from "../../store/user/userSlice";
import { getCustomerName, setCustomerId, setCustomerName } from "../../shared/utils";

const CustomerList = () => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const [customerSelected, setCustomer] = useState("");

  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;

  const navigate = useNavigate();

  useDocumentTitle("Customers");

  const { customerStore } = useStore();
  const { getCustomerList } = customerStore;
  const [customers, setCustomers] = useState(new CustomerListQryOutput());

  const [osoUser, setOsoUser] = useState<OsoUser>(new OsoUser());
  const [searchQuery, setSearchQuery] = useState("");
  const [customersWithMowers, setCustomersWithMowers] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      if (
        user![CLAIM_OSO_APP_USER_ID] === undefined ||
        user![CLAIM_OSO_APP_COMPANY_ID] === undefined ||
        user![CLAIM_OSO_APP_IS_SUPER_ADMIN] === undefined ||
        user![CLAIM_OSO_APP_IS_SUPER_ADMIN] === false
      ) {
        navigate("/");
        return;
      }

      const controller = new AbortController();
      getCustomerList(controller.signal)
        .then(function (res) {
          setCustomers(res);
        })
        .catch((err) => {
          logger.error("Please verify API is up and running.", err);
        });
      return () => {
        controller.abort();
      };
    }
  }, []);
  const isSelectedCustomer = (customer: string) => {
    let selCustomer = getCustomerName();
    return (
      selCustomer.trim().toLocaleLowerCase() === customer.trim().toLowerCase()
    );
  };

  const selectCustomer = (customerId: string, customerName: string) => {
    const customer = {
      customerId: customerId,
      customerName: customerName,
    } as Customer;
    setCustomer(customerName);

    setCustomerId(customerId);
    setCustomerName(customerName);
    toast.success(`Customer set to ${customerName}`, { theme: "colored" });
    navigate("/");
  };

  const RenderHeading = () => {
    if (isLoading || !isAuthenticated) {
      return <></>;
    }

    return (
      <>
        <div className="page-title">
          <Icon
            size="small"
            color="black"
            link
            name="arrow left"
            onClick={() => navigate("/")}
            className="no-border"
          />
          &nbsp;Customers (Admin View)
        </div>
        <br />
        <Divider section />
      </>
    );
  };

  const CustomerNameFilter = (e: any, data: any) => {
    setSearchQuery(data.value);
  };

  const customersWithMowerChange = (e: any) =>{
    if(customersWithMowers){
      setCustomersWithMowers(false);
    } else{
      setCustomersWithMowers(true);
    }
  }

  const RenderCustomerList = () => {
    return (
      <>
        {isLoading ||
          !isAuthenticated ||
          customers?.customerList?.length === 0 ? (
          <></>
        ) : (
          <>
            <div className="profile-label">Customer Name</div>
            <Input
              type="text"
              id="search-customer-name"
              name="search-customer-name"
              placeholder="Search by customer name"
              maxLength={30}
              size="large"
              value={searchQuery}
              onChange={CustomerNameFilter}
              className="mower-title-edit"
              fluid
              autoFocus="autoFocus"
            ></Input>
            <Checkbox label='Customer with mowers' className="fluid" checked={customersWithMowers} onChange={customersWithMowerChange} />

            <br />

            <List relaxed>
                {customers?.customerList?.filter(customer =>
                  customer.customerName.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) &&
                  customer.mowerCount >= (!customersWithMowers? 0: 1))
                .map(
                  (customer: Customer, index: number) => {
                    return (
                      <React.Fragment key={customer.customerId}>
                        <List.Item className="mower-list-item">
                          <List.Icon size="large" verticalAlign="middle">
                            <Label
                              circular
                              color={
                                isSelectedCustomer(customer.customerName)
                                  ? "blue"
                                  : "grey"
                              }
                              size="tiny"
                            ></Label>
                          </List.Icon>
                          <List.Content>
                            <List.Description className="pt-s mower-list-item-desc">
                              <div className="mower-list-item-title">
                                {customer.customerName}
                              </div>
                              {customer.address1} <br />
                              {customer?.address2?.length > 0
                                ? customer.address2
                                : ""}
                              {customer.city} {customer.state}{" "}
                              {customer.postalCode} <br />
                              <br />
                            </List.Description>
                            <Button
                              secondary
                              onClick={() =>
                                selectCustomer(
                                  customer.customerId,
                                  customer.customerName
                                )
                              }
                              style={{
                                display: isSelectedCustomer(customer.customerName)
                                  ? "none"
                                  : "",
                              }}
                            >
                              Select
                            </Button>
                          </List.Content>
                        </List.Item>
                        <List.Item></List.Item>
                      </React.Fragment>
                    );
                  }
                )}
            </List>
          </>
        )}
      </>
    );
  };

  const DesktopCustomerView = () => {
    return (
      <>
        {isLoading || !isAuthenticated ? (
          <></>
        ) : (
          <>
            <div style={{ paddingTop: "6px" }}></div>
            <div
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
                maxWidth: "400px",
              }}
            >
              <RenderHeading />
              <br />
              <RenderCustomerList />
            </div>
          </>
        )}
      </>
    );
  };

  const MobileCustomerView = () => {
    return (
      <>
        {isLoading || !isAuthenticated ? (
          <></>
        ) : (
          <>
            <div style={{ paddingTop: "6px" }}></div>

            <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <RenderHeading />
              <br />
              <RenderCustomerList />
            </div>
          </>
        )}
      </>
    );
  };

  const CustomerListView = () => {

    if (isMobile) {
      return <MobileCustomerView />;
    }
    return <DesktopCustomerView />;
  };

  return <CustomerListView />;
};

export default observer(CustomerList);
