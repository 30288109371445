import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { customerRegistration } from "../../types/registration/customerRegistration";

export default class CustomerStore {
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  createCustomer = async (customer: customerRegistration, signal?: any) => {
    return await agent.Customers.create(customer, signal);
  };

  getCustomerList = async (signal?: any) => {
    return await agent.Customers.getCustomerList(signal);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
}
