import React, { useEffect, useRef, useState } from "react";
import { useDocumentTitle } from "../../app/common/page-title";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { CLAIM_OSO_APP_COMPANY_ID, CLAIM_OSO_APP_USER_ID } from "../../types/constants";
import { observer } from "mobx-react-lite";
import { Button, Label, MenuItem, Segment, Tab, TabPane } from "semantic-ui-react";
import QrScanDetails from "./QrScanDetails";
import { MowerRegistration } from "../../types/registration/mowerRegistration";
import { useStore } from "../../app/stores/store";
import { toast } from "react-toastify";
import { logger } from "../../app/common/logger";
import AddMowerManually from "./AddMowerManually";
import { MowerDetailsEnter } from "./MowerDetailsEnter";
import { MowerReview } from "./MowerReview";
import { MowerValidateQryInput } from "../../types/mower/mower-validate-query";

const AddMower = (props: any) => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, user } =
    useAuth0();
  const [loading, setLoading] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [isQrScanner, setQrScanner] = useState(false);
  const [mowers, setMowers] = useState<Array<MowerRegistration>>(new Array<MowerRegistration>());
  const [mower, setMower] = useState<MowerRegistration>(new MowerRegistration());
  const { mowerStore } = useStore();
  const [isMowerReview, setMowerReviewState] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [indexNumber, setIndexNumber] = useState(0);

  const handleOnClick = () => {
    if (isQrScanner) {
      setQrScanner(false);
    } else {
      setQrScanner(true);
    }
  }

  const onQrScanClose = () => {
    setQrScanner(false);
  }

  const onChangeMowerDetails = (mowerDetail: MowerRegistration) => {
    let mowerObj = new MowerRegistration();
    mowerObj.customerId = props.customerId;
    mowerObj.mowerName = mowerDetail.mowerName;
    mowerObj.serialNumber = mowerDetail.serialNumber;
    mowerObj.isQrScan = mowerDetail.isQrScan;
    setMower(mowerObj);
  }

  const onDeleteMower = (index: number) =>{
    var filteredMowers = mowers.filter((mower: MowerRegistration, i: number) => i !== index);
    if(filteredMowers.length == 0){
      setMowerReviewState(false);
    }
    setMowers(filteredMowers);
  }

  const onEditMower = (index: number) => {
    var filteredMowers = mowers.filter((mower: MowerRegistration, i: number) => i === index);
    if(filteredMowers.length == 1){
      let mowerEdit = filteredMowers[0] as MowerRegistration;
      setMower(mowerEdit);
      setEdit(true);
      setMowerReviewState(false);
      setIndexNumber(index);
    }
  }

  const handleOnBackClick = () => {
    if(props.isMowerAdd){
      navigate("/");
    } else {
      props.onBackClick("account");
    }
  }

  const handleAddMower = () =>{
    let mowerInput = new MowerValidateQryInput(mower.serialNumber);
    mowerStore
      .validateMower(mowerInput)
      .then((response) => {
        if (response.isValid == true) {
          if(isEdit){
            var filteredMowers = mowers.filter((mower: MowerRegistration, i: number) => i === indexNumber);
            let mowerEdit = filteredMowers[0];
            if(!isMowerAdded(mower.serialNumber, mowerEdit.serialNumber)){
              mowerEdit.mowerName = mower.mowerName;
              mowerEdit.serialNumber = mower.serialNumber;
              setMower(mowerEdit);
              setEdit(false);
              setMowerReviewState(true);
            }
          } else {
            if(!isMowerAdded(mower.serialNumber, "")){
              mowers.push(mower);
              setMowerReviewState(true);
            }
          }
          
        }
      })
      .catch((err) => {
        logger.log(err);
        let msg: string = "";
        if (err.length && err.length > 0) {
          err.forEach((i: { [x: string]: string }) => {
            msg += i["ErrorMessage"] + " ";
          });

          setErrMsg(msg);
        } else{
          msg = err?.response?.data?.message;
          setErrMsg(msg);
        }
        toast.error(msg, {
          theme: "colored",
        });
      });
  }

  const isMowerAdded = (serialNumber: string, existingSerialNumber : string) =>{
    let filteredMower = mowers.filter(item => item.serialNumber === serialNumber);
    if((serialNumber != existingSerialNumber && filteredMower.length == 0) || (serialNumber == existingSerialNumber)){
      return false;
    } else{
      setErrMsg("This serial number is already added.");
      toast.error("This serial number is already added.", {
        theme: "colored",
      });
      return true;
    }
  }

  const onClickAddNewMower = () =>{
    setQrScanner(false);
    setMowerReviewState(false);
  }

  const handleSaveMower = () => {
    mowerStore
      .addMower(mowers)
      .then((response) => {
        if (response.done == true) {
          toast.success("Mower added successfully!", { theme: "colored" });
          if(props.isMowerAdd){
            navigate("/");
          } else {
            navigate("/registrationSuccess");
          }
        }
      })
      .catch((err) => {
        logger.log(err);
        let msg: string = "";
        if (err.length && err.length > 0) {
          err.forEach((i: { [x: string]: string }) => {
            msg += i["ErrorMessage"] + " ";
          });

          setErrMsg(msg);
        } else{
          msg = err?.response?.data?.message;
          setErrMsg(msg);
        }
        toast.error(msg, {
          theme: "colored",
        });
      });
  }
  const panes = [
    {
      menuItem: (
        <MenuItem key='scanQrCode' style={{ height: "75px", fontSize: "20px" }}>
          Scan QR Code
        </MenuItem>
      ),
      render: () =>
        <TabPane>
          {
            isQrScanner ? (
              <><QrScanDetails 
                  onChangeMowerDetails={onChangeMowerDetails}
                  onQrScanClose={onQrScanClose}
                 /></>
            ) : (
              <>
                <div>
                  <div className="ui">
                    <img className="ui centered medium image" src="/assets/images/mower.png" />
                  </div>

                  <div className="ui one cards" style={{ paddingLeft: '20%', paddingRight: '20%' }}>
                    <div className="card light-grey">
                      <div className="center aligned content">
                        Scan the QR code sticker on back of the red dial
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="ui one column stackable center aligned page grid">
                    <div className="column twelve wide">
                      <Button className="black large" onClick={handleOnClick}>Scan</Button>
                    </div>
                  </div>
                  <br />
                </div>
              </>
            )
          }

        </TabPane>
    },
    {
      menuItem: (
        <MenuItem key='manually' style={{ fontSize: "20px" }}>
          Enter Manually
        </MenuItem>
      ), render: () => <TabPane><AddMowerManually onChangeMowerDetails={onChangeMowerDetails}/></TabPane>
    }
  ]

  return (
    <>
      {!isAuthenticated ? (
        <></>
      ) : (
        <>
            {isMowerReview ? (<>
              <div className="ui cards">
                <div className="card" style={{ width: "100%", borderRadius: "10px" }}>
                  <div className="pd-30">
                    <Button onClick={onClickAddNewMower} className="large fluid black-bg-color">Add More Mowers</Button>
                    <br />
                    <MowerReview
                      mowers={mowers}
                      onDeleteMower={onDeleteMower}
                      onEditMower={onEditMower}
                    />
                  </div>
                </div>
              </div>

            </>) : (isEdit ? (
              <>
                  <div className="ui cards">
                    <div className="card" style={{ width: "100%", borderRadius: "10px" }}>
                      {mower.isQrScan ? (<>
                        <div className="ui cards pd-30" >
                          <div className="card" style={{ width: "100%", padding: "30px" }}>
                            <QrScanDetails
                              onChangeMowerDetails={onChangeMowerDetails}
                              mower={mower}
                            />
                          </div>
                        </div>
                        
                      </>) : (<>
                        <MowerDetailsEnter
                          onChangeMowerDetails={onChangeMowerDetails}
                          mower={mower}
                        /></>
                      )}
                    </div>
                  </div>
              </>) : (<>
                <Tab panes={panes} />
              </>))
          }
          <br />
          <Button onClick={handleOnBackClick} className="large">{props.isMowerAdd ? "Cancel" : "Back"}</Button>
          {isMowerReview ?
            <Button onClick={handleSaveMower} className="large primary-bg-color margin-Left-10">Done</Button>
            :( isEdit ? (
              <Button onClick={handleAddMower} className="large primary-bg-color margin-Left-10">Save</Button>
            ):(
              <Button onClick={handleAddMower} className="large primary-bg-color margin-Left-10">Add Mower</Button>
            )
            
            )
          }


        </>
      )}{" "}
    </>
  );
}

export default observer(AddMower);

