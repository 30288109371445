import { useAuth0 } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { logger } from "../../app/common/logger";
import { useDocumentTitle } from "../../app/common/page-title";
import {
  CLAIM_OSO_APP_COMPANY_ID,
  CLAIM_OSO_APP_USER_ID,
  LOCAL_STORAGE_CUSTOM_DATE,
} from "../../types/constants";
import {
  MowerActivityQryInput,
  MowerActivityQryOutput,
} from "../../types/mower/mower-activity-query";
import { useStore } from "../../app/stores/store";
import { getCustomerId, groupBy } from "../../shared/utils";
import { ApiSortOrderEnum } from "../../types/common/paged-result-model";
import { MowerActivityList } from "./MowerActivityList";
import { MowerActivityHeader } from "./MowerActivityHeader";
import { MowerActivityStats } from "./MowerActivityStats";
import moment from "moment";
import { MowerActivity } from "../../types/mower/mower-activity";
import { MapMarkerProps } from "../map/MapMarkers";
import { MapWithMarkers } from "../map/MapWithMarkers";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { GoToTop } from "./GoToTop";

const MowerActivityPage = () => {
  const { mowerId } = useParams();
  const dateFormat: string = "YYYY-MM-DD";

  const { isAuthenticated, isLoading, user } = useAuth0();
  const navigate = useNavigate();

  useDocumentTitle("Mower Activity");

  const { mowerStore } = useStore();
  const { getMowerActivity } = mowerStore;
  const [mowerActivities, setMowerActivities] = useState(
    new MowerActivityQryOutput()
  );
  const [mowerActivitiesByDate, setMowerActivitiesByDate] = useState(
    new Map<string, MowerActivity[]>()
  );

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [selectedDate, setSelectedDate] = useState("Today");
  const [sortOrder, setSortOrder] = useState(ApiSortOrderEnum.Desc);
  const [showStats, setShowStats] = useState(true);
  const [loadNextPage, SetLoadNextPage] = useState(0);
  const [reloadWithSort, SetReloadWithSort] = useState(0);
  const [reloadWithDateFilter, SetReloadWithDateFilter] = useState(0);
  const [highlightedLocationIndex, SetHighlightedLocationIndex] = useState(-1);

  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;
  const [isActivityLoading, setIsActivityLoading] = useState(true);
  const [mowerTitle, setMowerTitle] = useState("");
  const [mowerSerialNumber, setMowerSerialNumber] = useState("");
  const [mapMarkers, setMapMarkers] = useState<MapMarkerProps[]>([]);
  const [fetchError, setFetchError] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const fetchMowerActivity = (controller: AbortController) => {
    let from = moment(fromDate);
    let to = moment(toDate);

    if (localStorage.getItem(LOCAL_STORAGE_CUSTOM_DATE)) {
      let json = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_CUSTOM_DATE) || "{}"
      );
      if (json && json!["fromDate"] && json!["toDate"]) {
        const begin = moment(json!["fromDate"]);
        const end = moment(json!["toDate"]);
        if (from <= to) {
          setFromDate(moment(begin.format(dateFormat)).toDate());
          setToDate(moment(end.format(dateFormat)).toDate());
          setSelectedDate(
            begin.format("MMM DD") + " - " + end.format("MMM DD")
          );
          localStorage.removeItem(LOCAL_STORAGE_CUSTOM_DATE);
          from = begin;
          to = end;
        }
      }
    }

    let customerId = getCustomerId(user![CLAIM_OSO_APP_COMPANY_ID]);
    let id = mowerId!;
    const pageNo = currentPage + 1;
    let input = new MowerActivityQryInput(
      customerId,
      id,
      from,
      to,
      pageNo,
      sortOrder
    );
    getMowerActivity(input, controller.signal)
      .then(function (res) {
        //TODO: Do this better. Bandaid fix for the demo version.
        if (res) {
          setFetchError("");
          setIsActivityLoading(false);
          let list = res;
          setMowerTitle(res.mowerTitle);
          setMowerSerialNumber(res.serialNumber);
          if (mowerActivities && mowerActivities?.mowerActivities) {
            list.mowerActivities = mowerActivities.mowerActivities.concat(
              list.mowerActivities
            );
          }

          if (list?.mowerActivities?.length > 0) {
            const markers: MapMarkerProps[] = [];
            for (let i = 0; i < res.mowerActivities?.length; i++) {
              if (
                res.mowerActivities[i].latitude !== 0 &&
                res.mowerActivities[i].longitude !== 0
              ) {
                const marker: MapMarkerProps = {
                  color: getIconColorByStatus(res.mowerActivities[i].status),
                  iconLetter: (
                    res.mowerActivities[i].mowerTitle ??
                    res.mowerActivities[i].mowerName
                  ).charAt(0),
                  latitude: res.mowerActivities[i].latitude,
                  longitude: res.mowerActivities[i].longitude,
                };
                markers.push(marker);
              }
            }

            setMapMarkers(markers);
            setMowerActivities(list);

            setCurrentPage(list?.pagination?.currentPage);
            setLastPage(list?.pagination?.pageCount);
            setSortOrder(sortOrder);
            const groupByDates: Map<string, MowerActivity[]> = groupBy<
              string,
              MowerActivity
            >(
              list?.mowerActivities,
              (x) => x?.mowerActivityDate?.toString().split("T")[0]
            );

            setMowerActivitiesByDate(groupByDates);
            if(list?.pagination?.currentPage > 1 && !isMobile){
              var scrollPositionToSet = Number(document.getElementById("mowerActivityColumn")?.scrollHeight)
              - 220;
              if(scrollPositionToSet == scrollPosition){
                scrollPositionToSet++;
              }
              setScrollPosition(scrollPositionToSet);
            } else {
              setScrollPosition(scrollPosition+1);
            }
          }
        }
      })
      .catch((err) => {
        logger.error("Please verify API is up and running.", err);
        setFetchError(err.message);
      });
  };

  useEffect(() => {
    document.getElementById("mowerActivityColumn")?.scrollTo(0, scrollPosition);
  }, [scrollPosition]);

  const mowerActivityOnScroll = () => {
    const topBtn = document.getElementById('topbtn');
    var scrollPosition = document.getElementById("mowerActivityColumn")?.scrollTop;
    if (topBtn && scrollPosition && scrollPosition > 30) {
      topBtn.style.display = "block"
    } else if (topBtn) {
      topBtn.style.display = "none"
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (
        user![CLAIM_OSO_APP_USER_ID] === undefined ||
        user![CLAIM_OSO_APP_COMPANY_ID] === undefined
      ) {
        navigate("/customer-registration");
        return;
      }

      if (mowerId === null) {
        navigate("/");
        return;
      }

      SetHighlightedLocationIndex(-1);
      const controller = new AbortController();
      fetchMowerActivity(controller);
      return () => {
        controller.abort();
      };
    }
  }, [reloadWithSort, reloadWithDateFilter]);

  const getIconColorByStatus = (status: string): string => {
    if (status.trim().toLowerCase() === "on") return "green";
    if (status.trim().toLowerCase() === "active") return "blue";
    return "grey";
  };

  const LoadMoreMowerActivities = (e: any, data: any) => {
    const controller = new AbortController();
    if (currentPage < lastPage) {
      setIsActivityLoading(true);
      SetLoadNextPage(loadNextPage + 1);
      fetchMowerActivity(controller);
      return () => {
        controller.abort();
      };
    }
  };

  const selectSortOrder = (event: any, data: any) => {
    if (sortOrder.toString() !== data.value.toString()) {
      setIsActivityLoading(true);
      setMowerActivities(new MowerActivityQryOutput());
      setMowerActivitiesByDate(new Map<string, MowerActivity[]>());
      setCurrentPage(0);
      setSortOrder(parseInt(data.value));
      SetReloadWithSort(reloadWithSort + 1);
    }
  };

  const DesktopView = () => {
    return (
      <>
        {isLoading || !isAuthenticated || mowerActivities?.mowerId == "" ? (
          <></>
        ) : (
          <>
            <Grid
              columns={2}
              style={{
                height: "100%",
                width: "100%",
                paddingRight: "0px",
                margin: "0px",
              }}
            >
              <Grid.Column id="mowerActivityColumn" onScroll={mowerActivityOnScroll}
                style={{
                  maxWidth: "425px",
                  height: "100vh",
                  overflowY: "scroll",
                  overflowX: "hidden"
                }}
              >
                
                {showStats ? (
                  <MowerActivityHeader
                    mowerActivities={mowerActivities}
                    selectedDate={selectedDate}
                    sortOrder={sortOrder}
                    selectSortOrder={selectSortOrder}
                    navigate={navigate}
                    moment={moment}
                    dateFormat={dateFormat}
                    fromDate={fromDate}
                    toDate={toDate}
                    mowerId={mowerId}
                    setMowerActivities={setMowerActivities}
                    setMowerActivitiesByDate={setMowerActivitiesByDate}
                    setCurrentPage={setCurrentPage}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    SetReloadWithDateFilter={SetReloadWithDateFilter}
                    reloadWithDateFilter={reloadWithDateFilter}
                    setSelectedDate={setSelectedDate}
                    mowerTitle={mowerTitle}
                    mowerSerialNumber={mowerSerialNumber}
                    setIsActivityLoading={setIsActivityLoading}
                  />
                ) : (
                  <></>
                )}
                {showStats ? (
                  <MowerActivityStats mowerActivities={mowerActivities} />
                ) : (
                  <></>
                )}
                {showStats ? (
                  <MowerActivityList
                    paddingRight="10px"
                    mowerActivities={mowerActivities}
                    mowerActivitiesByDate={mowerActivitiesByDate}
                    highlightedLocationIndex={highlightedLocationIndex}
                    currentPage={currentPage}
                    lastPage={lastPage}
                    LoadMoreMowerActivities={LoadMoreMowerActivities}
                    moment={moment}
                    isDataLoading={isActivityLoading}
                    error={fetchError}
                  />
                ) : (
                  <></>
                )}
              </Grid.Column>
              <Grid.Column
                style={{
                  width: "100%",
                  height: "100vh",
                  flex: "1",
                  padding: "0px",
                }}
              >
                <MapWithMarkers
                  markers={mapMarkers}
                  SetHighlightedLocationIndex={SetHighlightedLocationIndex}
                  localStorageMapBound=""
                />
              </Grid.Column>
            </Grid>
          </>
        )}
      </>
    );
  };

  const MobileView = () => {
    return (
      <>
        {isLoading || !isAuthenticated || mowerActivities?.mowerId == "" ? (
          <></>
        ) : (
          <>
            <div className="PanelContent">
              <MapWithMarkers
                markers={mapMarkers}
                SetHighlightedLocationIndex={SetHighlightedLocationIndex}
                localStorageMapBound=""
              />{" "}
              <br />
            </div>
            <div
              className="PanelContent scroll-container"
              style={{ paddingLeft: "10px" }}
            >
              {showStats ? (
                <MowerActivityHeader
                  mowerActivities={mowerActivities}
                  selectedDate={selectedDate}
                  sortOrder={sortOrder}
                  selectSortOrder={selectSortOrder}
                  navigate={navigate}
                  moment={moment}
                  dateFormat={dateFormat}
                  fromDate={fromDate}
                  toDate={toDate}
                  mowerId={mowerId}
                  setMowerActivities={setMowerActivities}
                  setMowerActivitiesByDate={setMowerActivitiesByDate}
                  setCurrentPage={setCurrentPage}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                  SetReloadWithDateFilter={SetReloadWithDateFilter}
                  reloadWithDateFilter={reloadWithDateFilter}
                  setSelectedDate={setSelectedDate}
                  mowerTitle={mowerTitle}
                  mowerSerialNumber={mowerSerialNumber}
                  setIsActivityLoading={setIsActivityLoading}
                />
              ) : (
                <></>
              )}
              <br />
              {showStats ? (
                <MowerActivityStats mowerActivities={mowerActivities} />
              ) : (
                <></>
              )}
              {showStats ? (
                <MowerActivityList
                  paddingRight="10px"
                  mowerActivities={mowerActivities}
                  mowerActivitiesByDate={mowerActivitiesByDate}
                  highlightedLocationIndex={highlightedLocationIndex}
                  currentPage={currentPage}
                  lastPage={lastPage}
                  LoadMoreMowerActivities={LoadMoreMowerActivities}
                  moment={moment}
                  isDataLoading={isActivityLoading}
                />
              ) : (
                <></>
              )}
            </div>
            {/*</Panel>
          </PanelGroup>*/}
          </>
        )}
      </>
    );
  };

  const RenderMowerActivityView = () => {
    if (isMobile) {
      return <MobileView />;
    }
    return <DesktopView />;
  };

  return <RenderMowerActivityView />;
};

export default observer(MowerActivityPage);
