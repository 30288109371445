import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Message } from "semantic-ui-react";

const RegistrationSuccessPage = () => {
  const { logout, user } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      logout();
    }, 5000);
    setTimeout(() => {
      navigate("/");
    }, 7000);
  }, []);

  return (
    <>
      <Card.Content>
        <Message
          positive
          header="User registration successful!"
          content="Please login again. Redirecting you to login page in 5 seconds."
        />
      </Card.Content>
    </>
  );
};

export default RegistrationSuccessPage;
