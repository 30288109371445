import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { OsoUser } from "../../types/registration/user";
import { AcceptTermsAndConditionsCmdInput } from "../../types/registration/accept-terms-and-conditions";

export default class UserStore {
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  createUser = async (user: OsoUser, signal?: any) => {
    return await agent.Users.create(user, signal);
  };

  getCurrentUser = async (signal?: any) => {
    return await agent.Users.getCurrentUser(signal);
  };

  acceptTermsAndConditions = async (request: AcceptTermsAndConditionsCmdInput, signal?: any) => {
    return await agent.Users.accepttermsAndConditions(request, signal);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };


  resendAuth0VerificationEmail = async (signal?: any) => {
    return await agent.Users.resendAuth0VerificationEmail(signal);
  }
}
