import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useStore } from "../../app/stores/store";
import { useAuth0 } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Button, Card, Form, Message, Segment } from "semantic-ui-react";
import { OsoUser } from "../../types/registration/user";
import { CommonRegularExpressions } from "../../shared/regular-expressions";
import Loading from "../../app/layout/loading";
import TextInput from "../../app/common/form/text-input";
import { logger } from "../../app/common/logger";
import { toast } from "react-toastify";
import {
  CLAIM_OSO_APP_COMPANY_ID,
  CLAIM_OSO_APP_USER_ID,
} from "../../types/constants";
import { useDocumentTitle } from "../../app/common/page-title";

const UserRegistrationPage = () => {
  const commonRegex = CommonRegularExpressions;

  const { userStore, commonStore } = useStore();
  const { getCurrentUser } = userStore;
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } =
    useAuth0();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [errMsg, setErrMsg] = useState("");

  const [osoUser, setOsoUser] = useState<OsoUser>(new OsoUser());

  useDocumentTitle("Register");

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }

    if (isAuthenticated) {
      if (
        user![CLAIM_OSO_APP_USER_ID] !== undefined ||
        user![CLAIM_OSO_APP_COMPANY_ID] !== undefined
      ) {
        navigate("/");
        return;
      }

      if (user != null) {
        let x = new OsoUser();
        x.email = user?.email ?? "";

        setOsoUser(x);
      }

      getAccessTokenSilently().then(() => {
        getCurrentUser().then((result) => {
          setOsoUser(result.user);
          setLoading(false);
        });
      });
    }
  }, []);

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required("First Name is required")
      .matches(
        commonRegex.firstNameOrLastName,
        "Special characters not allowed"
      ),
    lastName: yup
      .string()
      .required("Last Name is required")
      .matches(
        commonRegex.firstNameOrLastName,
        "Special characters not allowed"
      ),
    email: yup
      .string()
      .required("Email is required")
      .email("Enter a valid email"),
    phoneNumber: yup.string().required("Phone Number is required"),
    agree: yup
      .bool()
      .oneOf([true], "accept the terms and conditions to continue"),
  });

  const handleFormSubmit = (
    user: OsoUser,
    setFieldError: any,
    setSubmitting: any
  ) => {
    userStore
      .createUser(user)
      .then((response) => {
        if (response !== null) {
          setSubmitting(false);
          toast.success("Success!", { theme: "colored" });
          navigate("/registrationSuccess");
          //window.location.href = "/";
        }
      })
      .catch((err) => {
        logger.log(err);
        let msg: string = "";
        if (err.length && err.length > 0) {
          err.forEach((i: { [x: string]: string }) => {
            msg += i["ErrorMessage"] + " ";
          });

          setErrMsg(msg);
        }
        toast.error("There was an issue completing the registration.", {
          theme: "colored",
        });
      });
    setSubmitting(false);
  };

  if (isLoading) return <Loading content="Loading user info..." />;

  return (
    <>
      {!isAuthenticated ? (
        <></>
      ) : (
        <>
          <Card.Content>
            <Message
              positive
              icon="info circle"
              header="User Account Details"
              content="The account information below will be used for account 
                        authentication and recovery.
                        Complete the required information below to proceed."
            />
          </Card.Content>
          <Card.Content>
            <Segment clearing>
              <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={osoUser}
                onSubmit={(values, { setFieldError, setSubmitting }) => {
                  return handleFormSubmit(values, setFieldError, setSubmitting);
                }}
              >
                {({ handleSubmit, isSubmitting, isValid }) => (
                  <Form
                    className="ui form"
                    onSubmit={handleSubmit}
                    autoComplete="Off"
                  >
                    <TextInput
                      placeholder="First Name"
                      name="firstName"
                      label="First Name"
                      required
                      maxLength={50}
                    />
                    <TextInput
                      placeholder="Last Name"
                      name="lastName"
                      label="Last Name"
                      required
                      maxLength={50}
                    />
                    <TextInput
                      placeholder="Phone Number"
                      name="phoneNumber"
                      label="Phone Number"
                      required
                      maxLength={16}
                    />
                    <TextInput
                      placeholder="Email"
                      name="email"
                      label="Email (Read Only)"
                      required
                      readOnly
                      maxLength={256}
                    />

                    {/*    
                                <Form.Field required>
                                    <Checkbox id="agree" name="agree" label='I agree to the Terms and Conditions' />
                                </Form.Field>
                                */}
                    <Button
                      disabled={!isValid}
                      loading={isSubmitting}
                      floated="right"
                      positive
                      type="submit"
                      content="Continue"
                    />
                  </Form>
                )}
              </Formik>
            </Segment>
          </Card.Content>
          {errMsg === "" ? (
            <></>
          ) : (
            <>
              <Card.Content>
                <Message
                  error
                  icon="question circle"
                  header="User Registration Failed."
                  content={
                    "Please review the following errors and fix them to continue. " +
                    errMsg
                  }
                />
              </Card.Content>
            </>
          )}
        </>
      )}{" "}
    </>
  );
};

export default observer(UserRegistrationPage);
