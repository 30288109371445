import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ProfilePage from "./ProfilePage";
import { ToastContainer } from "react-toastify";
import AuthProvider from "../app/layout/auth-provider";
import ProtectedRoute from "../app/layout/protected-route";
import HomePage from "./home/HomePage";
import NavBarLayout from "../app/layout/nav-bar-layout";
import UserForm from "./registration/UserRegistrationPage";
import CustomerRegistrationForm from "./registration/CustomerRegistrationPage";
import RegistrationPage from "./registration/RegistrationPage"
import SplashPage from "./SplashPage";
import MowerActivityPage from "./mower-activity/MowerActivityPage";
import customerList from "./customers/CustomerList";
import mowerList from "./mowers/MowersList";
import mower from "./mowers/MowerDetails";
import customDateRange from "./mower-activity/CustomDateRange";
import PageNotFound from "./PageNotFound";
import axios from "axios";
import { configUrl } from "../config";
import { configureAxios } from "../app/api/agent";
import { ConfigContext } from "../useConfig";
import RegistrationSuccessPage from "./registration/RegistrationSuccessPage";
import AddMowerPage from "./mower-registration/AddMowerPage";

const App = () => {
  const { config, setConfig } = useContext(ConfigContext);
  const [configLoadingState, setConfigLoadingState] = useState<
    "loading" | "ready" | "error"
  >("loading");

  useEffect(() => {
    axios({ url: configUrl, baseURL: "", method: "get" })
      .then((response) => {
        setConfig(response.data);
        console.log("config from config.json: ", response.data);
        setConfigLoadingState("ready");
        configureAxios(response.data);
      })
      .catch((e) => {
        setConfigLoadingState("error");
        console.log(e);
      });
  }, [setConfig]);
  if (configLoadingState === "loading") {
    return <>Loading the app config....</>;
  }
  if (configLoadingState === "error") {
    return (
      <p style={{ color: "red", textAlign: "center" }}>
        Error fetching configuration file
      </p>
    );
  }

  return (
    <AuthProvider>
      <>
        <ToastContainer
          position="bottom-right"
          hideProgressBar
          autoClose={3000}
          closeOnClick
        />
        {config.env.trim().toLowerCase() === "test" ? (
          <div
            style={{
              zIndex: 1,
              width: "100%",
              textAlign: "center",
              fontSize: "smaller",
              height: "fit-content",
              backgroundColor: "lightcyan"
            }}
          >
            <span>Test Portal</span>
          </div>
        ) : (
          <></>
        )}
        <Routes>
          <Route path="/splash" element={<SplashPage />} />
          <Route element={<NavBarLayout />}>
            <Route path="/" element={<ProtectedRoute component={HomePage} />} />
            <Route
              path="/register"
              element={<ProtectedRoute component={UserForm} />}
            />
            <Route
              path="/customer-registration"
              element={<ProtectedRoute component={RegistrationPage} />}
            />
            <Route
              path="/profile"
              element={<ProtectedRoute component={ProfilePage} />}
            />
            <Route
              path="/customers"
              element={<ProtectedRoute component={customerList} />}
            />
            <Route
              path="/mowers"
              element={<ProtectedRoute component={mowerList} />}
            />
            <Route
              path="/mower"
              element={<ProtectedRoute component={mower} />}
            />
            <Route
              path="/add-mower"
              element={<ProtectedRoute component={AddMowerPage} />}
            />
            <Route
              path="/date-range/:mowerId"
              element={<ProtectedRoute component={customDateRange} />}
            />
            <Route
              path="/mower-activity/:mowerId/"
              element={<ProtectedRoute component={MowerActivityPage} />}
            />
            <Route
              path="/registrationSuccess"
              element={<RegistrationSuccessPage />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </>
    </AuthProvider>
  );
};

export default App;
