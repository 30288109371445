import { LocalStorageCache, useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosRequestConfig } from "axios";
import React, { useEffect, useState } from "react";

export const AxiosInterceptorContext = React.createContext<{
  isTokenSet: boolean;
}>({
  isTokenSet: false,
});

const setAxiosTokenInterceptor = async (accessToken: string): Promise<void> => {
  axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
    if (accessToken) {
      const locale =
        (navigator.languages && navigator.languages[0]) || navigator.language;
      const timeZoneOffset = new Date().getTimezoneOffset();
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (config.headers) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
        config.headers["x-client-timezone"] = timeZone;
        config.headers["x-client-timezone-offset"] = timeZoneOffset;
        config.headers["x-client-locale"] = locale;
      } else {
        config.headers = {
          Authorization: `Bearer ${accessToken}`,
          "x-client-timezone": timeZone,
          "x-client-timezone-offset": timeZoneOffset,
          "x-client-locale": locale,
        };
      }
    }
    return config;
  });
};

type AxiosInterceptorProviderProps = { children: React.ReactNode };

export const AxiosInterceptorProvider = ({
  children,
}: AxiosInterceptorProviderProps) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [isTokenSet, setIsTokenSet] = useState(false);

  useEffect(() => {
    const getAccessToken = async () => {
      const refresh_token = new LocalStorageCache();
      const keys = refresh_token.allKeys();
      const key = keys.find((k) => k.includes("auth0spa")) ?? "";
      const refresh_token_value = refresh_token.get(key) as any;
      if (refresh_token_value) {
        const access_token = refresh_token_value["body"]["access_token"];
        //TODO: Should this be done for non-safari browsers?
        //const token = await getAccessTokenSilently();
        await setAxiosTokenInterceptor(access_token);
        setIsTokenSet(true);
      } else {
        await loginWithRedirect();
      }
    };
    //TODO: Jay -> Should we be doing something with the token?
    getAccessToken();
  }, [getAccessTokenSilently]);

  return (
    <AxiosInterceptorContext.Provider value={{ isTokenSet }}>
      {children}
    </AxiosInterceptorContext.Provider>
  );
};
