import React from "react";
import { Button, Icon, List } from "semantic-ui-react";
import { toHoursOrMinutes } from "../../shared/utils";
import MowerStatusIcon from "../icons/MowerStatusIcon";

export interface MowerListSummary {
  mowerId: string;
  lastBladeStatus: string;
  mowerTitle: string;
  mowerName: string;
  location: string;
  onTime: number;
  cutTime: number;
  serialNumber: string;
  streetNumber: string;
  streetName: string;
  city: string;
  state: string;
  zipCode: string;
  cutTimeAtLastLocation : number;
}

interface MowerListProps {
  title: string;
  highlightedLocationIndex: number;
  mowers: Array<MowerListSummary>;
  user: any;
  callback: (mowerId: string) => void;
}

const MowerList = (props: MowerListProps) => {
  return (
    <>
      <div className="mower-list-title" style={{ paddingTop: "10px" }}>
        <Icon circular inverted color="grey" name="lightning" />
        {props.title}
      </div>
      <div id="mower-list">
        <List
          relaxed
          style={{ paddingRight: "10px", height: "100%", paddingTop: "20px" }}
        >
          {props.mowers?.map((mower: MowerListSummary, index: number) => {
            return (
              <React.Fragment key={mower.mowerId}>
                <List.Item id="mower-list-item-id"
                  className={
                    "mower-list-item " +
                    (props.highlightedLocationIndex === index
                      ? "selected-mower-location"
                      : "")
                  }
                >
                  <List.Content verticalAlign="top" >
                    <List.Description className="mower-list-item-desc">
                      <div
                        onClick={() => props.callback(mower.mowerId)}
                        className="mower-list-item"
                      >
                        <div
                          className={`mower-list-item-title ${mower.lastBladeStatus.toLowerCase() === "offline"
                              ? "home-page-mower-list-offline"
                              : ""
                            }`}
                        >
                          {mower.mowerTitle && mower.mowerTitle.length > 0
                            ? mower.mowerTitle
                            : mower.serialNumber}

                        </div>
                        {props.user?.isSubscribed ? (<>
                          {mower.lastBladeStatus.toLowerCase() !== "offline" &&
                            mower.streetNumber &&
                            mower.streetName ? (
                            <>
                              <div>
                                {mower.streetNumber} {mower.streetName},
                              </div>
                              <div>
                                {mower.city}, {mower.state} {mower.zipCode}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {mower.lastBladeStatus.toLowerCase() !== "offline" &&
                            (!mower.streetNumber || !mower.streetName) ? (
                            <>
                              <div>Location not available</div>
                            </>
                          ) : (
                            <></>
                          )}
                          <List.Icon
                            size="small"
                            verticalAlign="top"
                            style={{
                              paddingTop: "3px",
                              marginRight: "0px",
                            }}
                          >
                            <MowerStatusIcon statusText={mower.lastBladeStatus} />
                          </List.Icon>{" "}
                          {mower.lastBladeStatus.toLowerCase() === "on" ? (
                            <>
                              On for {toHoursOrMinutes(mower.onTime?.toString())} <span>&nbsp; •&nbsp; Today's cut time: </span> {toHoursOrMinutes(mower.cutTime?.toString())}
                            </>
                          ) : (
                            <></>
                          )}
                          {mower.lastBladeStatus.toLowerCase() === "active" ? (
                            <>
                              Cutting for {toHoursOrMinutes(mower.cutTimeAtLastLocation?.toString())}<span>&nbsp; •&nbsp; Today's cut time: </span>{toHoursOrMinutes(mower.cutTime?.toString())}
                            </>
                          ) : (
                            <></>
                          )}
                          {mower.lastBladeStatus.toLowerCase() === "offline" ? (
                            <>
                              Offline<span>&nbsp; •&nbsp; Today's cut time: </span>{toHoursOrMinutes(mower.cutTime?.toString())}
                            </>
                          ) : (
                            <></>
                          )}
                        </>) : (<></>)}
                      </div>
                    </List.Description>
                  </List.Content>
                  
                </List.Item>
                <hr className="hrBorder" />
              </React.Fragment>
            );
          })}
        </List>
      </div>
    </>
  );
};

export default MowerList;
