import { Outlet } from "react-router-dom";
import { Container } from "semantic-ui-react";

const NavBarLayout = () => {
  return (
    <>
      <Container style={{ paddingTop: "1em", paddingBottom: "0.5em" }}>
        <Outlet />
      </Container>
    </>
  );
};
export default NavBarLayout;
