
  export interface CustomerListQryOutput {   
    customerList: Customer[];
  }

  export interface Customer {
    customerId: string;
    customerName: string;
    customerTypeName: string;
    parentCustomerName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode:string;  
    mowerCount: number;  
  }

  export class CustomerListQryOutput implements CustomerListQryOutput {
    constructor(init?: CustomerListQryOutput) {
      if (init !== undefined){       
        Object.assign(this, init);
      }
    }   
  }