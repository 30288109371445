import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import {
  OsoUser,
  UserFormValues,
  UserRegistrationValues,
} from "../../types/registration/user";
import { store } from "../stores/store";
import { logger } from "../common/logger";
import {
  MowersSummaryQuery,
  MowersSummaryResponse,
} from "../../types/mower/dashboard";
import { CustomerListQryOutput } from "../../types/customer/customer";
import {
  ChangeMowerTitleCmdInput,
  ChangeMowerTitleCmdOutput,
} from "../../types/mower/update-mower-title-models";
import {
  MowerActivityQryInput,
  MowerActivityQryOutput,
} from "../../types/mower/mower-activity-query";
import {
  MowerListQryInput,
  MowerListQryOutput,
} from "../../types/mower/mower-list-query";
import { Config } from "../../config";
import { customerRegistration } from "../../types/registration/customerRegistration";
import { MowerRegistration, MowerRegistrationCmdInput, MowerRegistrationCmdOutput } from "../../types/registration/mowerRegistration";
import { MowerValidateQryInput, MowerValidateQryOutput } from "../../types/mower/mower-validate-query";
import { StateListQryOutput } from "../../types/common/CountryState";
import { AcceptTermsAndConditionsCmdInput, AcceptTermsAndConditionsCmdOutput } from "../../types/registration/accept-terms-and-conditions";

export const configureAxios = (config: Config) => {
  axios.defaults.baseURL = config.apiBaseUrl;
  axios.defaults.timeout = config.apiDefaultTimeoutInSeconds * 1000;
  axios.defaults.headers.common["content-type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["content-type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
};


axios.interceptors.response.use(
  async (response) => {
    try {
      return response;
    } catch (error) {
      logger.error(error);
      return await Promise.reject(error);
    }
  },
  (error) => {
    logger.error(error);
    if (error === undefined || error.response === undefined) {
    } else {
      const { data, status } = error.response!;
      switch (status) {
        case 400:
          if (data.errors) {
            const modalStateErrors = [];
            for (const key in data.errors) {
              modalStateErrors.push(data.errors[key]);
            }
            throw modalStateErrors.flat();
          } else {
            toast.error(data);
          }
          break;
        case 401:
          toast.error(data.message);
          break;
        case 403:
          toast.error(data.message);
          break;
        case 404:
          toast.error("Not found");
          break;
        case 500:
          store.commonStore.setServerError(data);
          //toast.error("Unexpected error from the server");
          break;
        default:
          toast.error(error!.message);
          break;
      }
      return Promise.reject(error);
    }
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response?.data;

const requests = {
  get: <T>(url: string, params?: any, signal?: any) =>
    axios.get<T>(url, { params: params, signal: signal }).then(responseBody),
  post: <T>(url: string, body: {}, signal?: any) =>
    axios.post<T>(url, body, { signal: signal }).then(responseBody),
  put: <T>(url: string, body: {}, signal?: any) =>
    axios.put<T>(url, body, { signal: signal }).then(responseBody),
  del: <T>(url: string, params?: any, signal?: any) =>
    axios.delete<T>(url, { params: params, signal: signal }).then(responseBody),
};

const Users = {
  create: (user: OsoUser, signal?: any) => {
    return requests.post<UserRegistrationValues>(
      "user/register-user",
      user,
      signal
    );
  },
  getCurrentUser: (signal?: any) => {
    return requests.get<UserFormValues>("user/current-user-info", signal);
  },
  accepttermsAndConditions: (input: AcceptTermsAndConditionsCmdInput, signal: any) => {
    return requests.put<AcceptTermsAndConditionsCmdOutput>(
      "user/accept-terms-and-condition",
      input,
      signal
    );
  },
  resendAuth0VerificationEmail : (signal: any) =>{ 
    return requests.put<boolean>("user/resend-auth0-email", signal);
  }
};

const Mowers = {
  getAllMowerList: (input: MowerListQryInput, signal?: any) => {
    const req = { customerId: input.customerId };
    return requests.get<MowerListQryOutput>("mower/all-mowers", req, signal);
  },
  getAllMowersActivity: (input: MowersSummaryQuery, signal?: any) => {
    const req = { customerId: input.customerId };
    return requests.get<MowersSummaryResponse>(
      "mower/all-mowers-activity",
      req,
      signal
    );
  },
  getMowerActivity: (input: MowerActivityQryInput, signal?: any) => {
    const req = {
      customerId: input.customerId,
      mowerId: input.mowerId.toString(),
      fromDate: input.fromDate.toISOString(true).slice(0, 10),
      toDate: input.toDate.toISOString(true).slice(0, 10),
      currentPage: input.currentPage.toString(),
      pageSize: input.pageSize.toString(),
      sortOrder: input.sortOrder.toString(),
    };
    return requests.get<MowerActivityQryOutput>(
      "mower/selected-mower-activity",
      req,
      signal
    );
  },
  updateMowerTitle: (input: ChangeMowerTitleCmdInput, signal: any) => {
    return requests.put<ChangeMowerTitleCmdOutput>(
      "mower/update-mower-title",
      input,
      signal
    );
  },
  addMower: (input: Array<MowerRegistration>, signal: any) => {
    var inputCommand = new MowerRegistrationCmdInput();
    inputCommand.mowers = input;
    return requests.post<MowerRegistrationCmdOutput>(
      "mower/add-mower",
      inputCommand,
      signal
    );
  },
  validateMower: (input: MowerValidateQryInput, signal: any) => {
    return requests.get<MowerValidateQryOutput>(
      "mower/validate-mower",
      input,
      signal
    );
  },
};

const Common = {
  ping: (signal?: any) => {
    return requests.get<any>("common/ping", signal);
  },

  states: (signal?: any) => {
    const req = { countryId: 1 };
    return requests.get<StateListQryOutput>("common/states", req, signal);
  },
};

const Customers = {
  create: (customer: customerRegistration, signal?: any) => {
    return requests.post<customerRegistration>(
      "customer/register-customer",
      customer,
      signal
    );
  },
  getCustomerList: (signal?: any) => {
    return requests.get<CustomerListQryOutput>(
      "customer/all-customer-list",
      signal
    );
  },
};

const agent = {
  Users,
  Common,
  Mowers,
  Customers,
};

export default agent;
