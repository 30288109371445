import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OsoUser } from "../../types/registration/user";
import userService from "../../services/user.service";
import { RootState } from "../rootStore";
import { AsyncState } from "../../types/common/AsyncState"


interface UserState extends AsyncState {
  user?: OsoUser | null;
}

const initialState: UserState = {
  user: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
};

export const getCurrentUser = createAsyncThunk(
  "users/getCurrentUser",
  async () => {
    try {
      return await userService.getCurrentUser();
    } catch (error) {
      console.log("Unable to get user info!", error);
    }
  }
);
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //GetCurrentUser
      .addCase(getCurrentUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload?.user;
      })
      .addCase(getCurrentUser.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.user = null;
      });
  },
});

export default userSlice.reducer;

export const selectedUser = (state: RootState) => {
  return state.user;
};
