import React, { useContext } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { AxiosInterceptorContext } from "../api/axios-interceptor";

const ProtectedRoute = ({ component, ...args }: any) => {
  const Component = withAuthenticationRequired(component, args);
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { isTokenSet } = useContext(AxiosInterceptorContext);
  return isAuthenticated && isTokenSet ? <Component /> : <></>;
  //return <Component />;
};

export default ProtectedRoute;
