import React from "react";
import { Dimmer, Loader, Segment, SemanticSIZES } from "semantic-ui-react";

interface Props {
  inverted?: boolean;
  content: string;
  size?: SemanticSIZES;
  inline?: boolean;
}

const Loading = ({
  inverted = true,
  content = "Loading...",
  size = "large",
  inline = false,
}: Props) => {
  return (
    <Dimmer active={true} inverted={inverted}>
      <Loader content={content} size={size} inline={inline} />
    </Dimmer>
  );
};

export default Loading;
