import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { ServerError } from "../../types/common/server-error";

export default class CommonStore {
  error: ServerError | null = null;
  appLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  setServerError = (error: ServerError) => {
    this.error = error;
  };

  setAppLoaded = () => {
    this.appLoaded = true;
  };

  ping = async (signal?: any) => {
    return await agent.Common.ping(signal);
  };

  countryStates = async (signal?: any) => {
    return await agent.Common.states(signal);
  };
}
