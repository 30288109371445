import { useEffect, useRef, useState } from "react";
import { Divider, Dropdown, Icon, Menu } from "semantic-ui-react";
import { ApiSortOrderEnum } from "../../types/common/paged-result-model";
import { LOCAL_STORAGE_CUSTOM_DATE } from "../../types/constants";
import { MowerActivity } from "../../types/mower/mower-activity";
import { MowerActivityQryOutput } from "../../types/mower/mower-activity-query";
import { DateRange, DateRangePicker } from "react-date-range";
import moment from "moment";
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const MowerActivityRenderDateFilters = (props: any) => {
  const dateFormat: string = "YYYY-MM-DD";
  const [open, setOpen] = useState(false);
  const refOne = useRef<HTMLInputElement>(null);

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)
  }, []);

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const selectMowerActivityDates = (event: any, data: any) => {
    let from = props.moment();
    let to = from.format(props.dateFormat);
    switch (data.text.trim().toLowerCase()) {
      case "today":
        props.setSelectedDate("Today");
        break;
      case "yesterday":
        from = from.subtract(1, "days").format(props.dateFormat);
        to = from;
        props.setSelectedDate("Yesterday");
        break;
      case "last 7 days":
        from = from.subtract(6, "days").format(props.dateFormat);
        props.setSelectedDate("Last 7 Days");
        break;
      case "last 30 days":
        from = from.subtract(1, "months").add(1, "days").format(props.dateFormat);
        props.setSelectedDate("Last 30 Days");
        break;
      case "all time":
        from = from.subtract(10, "years").format(props.dateFormat);
        props.setSelectedDate("All Time");
        break;
      case "custom date range":
        props.setSelectedDate("Custom Date Range");
        localStorage.removeItem(LOCAL_STORAGE_CUSTOM_DATE);
        //props.navigate("/date-range/" + props.mowerId);
        
        break;
    }

    if (
      from !== props.moment(props.fromDate).format(props.dateFormat) ||
      to !== props.moment(props.toDate).format(props.dateFormat)
    ) {
      getMowerActivities(from, to);
    }
  };

  const getMowerActivities =(from: any, to: any) =>{
    props.setMowerActivities(new MowerActivityQryOutput());
    props.setMowerActivitiesByDate(new Map<string, MowerActivity[]>());
    props.setCurrentPage(0);
    props.setFromDate(props.moment(from));
    props.setToDate(props.moment(to));
    props.SetReloadWithDateFilter(props.reloadWithDateFilter + 1);
    props.setIsActivityLoading(true);
  }

  const hideOnEscape = (e: any) => {
    if( e.key === "Escape" ) {
      setOpen(false)
    }
  }

  const hideOnClickOutside = (e: any) => {
    if( refOne.current && !refOne.current.contains(e.target) ) {
      setOpen(false);
    }
  }

  const handleDateRangeChange = (dates: any) => {
    range[0].startDate = dates.selection.startDate;
    range[0].endDate = dates.selection.endDate;
  };

  const selectDate =() =>{
    let from = moment(range[0].startDate).format(dateFormat);
    let to = moment(range[0].endDate).format(dateFormat);
    props.setSelectedDate(moment(range[0].startDate).format("MMM DD") + " - " + moment(range[0].endDate).format("MMM DD"));
    getMowerActivities(from, to);
  }

  return (
    <>
      <Menu compact style={{ borderRadius: "100px" }}>
        <Dropdown text={props.selectedDate} item>
          <Dropdown.Menu>
            <Dropdown.Item text="Today" onClick={selectMowerActivityDates} />
            <Dropdown.Item
              text="Yesterday"
              onClick={selectMowerActivityDates}
            />
            <Dropdown.Item
              text="Last 7 Days"
              onClick={selectMowerActivityDates}
            />
            <Dropdown.Item
              text="Last 30 Days"
              onClick={selectMowerActivityDates}
            />
            <Dropdown.Item text="All Time" onClick={selectMowerActivityDates} />
            <Dropdown.Divider />
            <Dropdown.Item
              text="Custom Date Range"
              onClick={() => setOpen(true)}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
      &nbsp;&nbsp;
      <Menu compact style={{ borderRadius: "100px" }}>
        <Dropdown
          text={
            props.sortOrder === ApiSortOrderEnum.Asc
              ? "Oldest First"
              : "Newest First"
          }
          item
        >
          <Dropdown.Menu>
            <Dropdown.Item
              text="Newest First"
              value="2"
              onClick={props.selectSortOrder}
            />
            <Dropdown.Item
              text="Oldest First"
              value="1"
              onClick={props.selectSortOrder}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
      <br />
      <br />
      <div className="calendarWrap">
        <div ref={refOne}>
          {open &&
            <DateRange
              onChange={handleDateRangeChange}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={range}
              months={1}
              maxDate = {new Date()}
              direction="horizontal"
              className="calendarElement"
            />
          }
          {open &&
            <div>
              <input type="button" className="ui compact menu " 
                style={{ borderRadius: "100px", float: "right", backgroundColor: "#3498db", color: "white", cursor: "pointer" }} 
                value="Select Date" 
                onClick={selectDate} />
              <br /><br /><br />
            </div>
          }
          
        </div>

      </div>
    </>
  );
};

export const MowerActivityHeader = (props: any) => {
  return (
    <>
      <div className="page-title">
        <Icon
          size="small"
          color="black"
          link
          name="arrow left"
          onClick={() => props.navigate("/")}
          className="no-border"
        />
        &nbsp;
        {props.mowerTitle && props.mowerTitle.length > 0
          ? props.mowerTitle
          : props.mowerSerialNumber}
        {"'s Activity"}
      </div>
      <br />
      

      <MowerActivityRenderDateFilters
        mowerActivities={props.mowerActivities}
        selectedDate={props.selectedDate}
        sortOrder={props.sortOrder}
        selectSortOrder={props.selectSortOrder}
        moment={props.moment}
        fromDate={props.fromDate}
        toDate={props.toDate}
        dateFormat={props.dateFormat}
        mowerId={props.mowerId}
        setMowerActivities={props.setMowerActivities}
        setMowerActivitiesByDate={props.setMowerActivitiesByDate}
        setCurrentPage={props.setCurrentPage}
        setFromDate={props.setFromDate}
        setToDate={props.setToDate}
        SetReloadWithDateFilter={props.SetReloadWithDateFilter}
        reloadWithDateFilter={props.reloadWithDateFilter}
        setSelectedDate={props.setSelectedDate}
        setIsActivityLoading={props.setIsActivityLoading}
      />

      <Divider section />
    </>
  );
};
