import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useStore } from "../../app/stores/store";
import { useAuth0 } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Button, Card, Form, Grid, GridColumn, Input, Message, Segment } from "semantic-ui-react";
import { OsoUser } from "../../types/registration/user";
import { CommonRegularExpressions } from "../../shared/regular-expressions";
import Loading from "../../app/layout/loading";
import TextInput from "../../app/common/form/text-input";
import { logger } from "../../app/common/logger";
import { toast } from "react-toastify";
import {
  CLAIM_OSO_APP_COMPANY_ID,
  CLAIM_OSO_APP_USER_ID,
} from "../../types/constants";
import { useDocumentTitle } from "../../app/common/page-title";
import { customerRegistration } from "../../types/registration/customerRegistration";
import { string } from "yargs";
import SelectInput from "../../app/common/form/select-input";

const CustomerRegistrationPage = (props: any) => {
    const commonRegex = CommonRegularExpressions;

  const { customerStore,  commonStore } = useStore();
  const { isAuthenticated, isLoading, user } =
    useAuth0();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const stateInitalValue = [{value: '', text: ''}];
  const [stateOptions, setStateOptions]=useState(stateInitalValue);

  const [customer, setCustomer] = useState<customerRegistration>(new customerRegistration());

  useDocumentTitle("Register Customer");

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }

    if (isAuthenticated) {
      if (
        user![CLAIM_OSO_APP_USER_ID] !== undefined ||
        user![CLAIM_OSO_APP_COMPANY_ID] !== undefined
      ) {
        navigate("/");
        return;
      }
    }
    if (user != null) {
      commonStore
      .countryStates()
      .then((response) => {
        if (response !== null) {
          var options = response.stateList.map(x =>{
            return{value: x.stateName, text: x.stateName}
          })
          setStateOptions(options);
        }
      });

      var cust = props.customerValueDefault as customerRegistration;
      cust.primaryUser.email = user?.email ?? "";

      setCustomer(cust);
    }
  }, []);

  const validationSchema = yup.object({
    customerName: yup.string().required("Customer Name is required"),
    primaryUser: yup.object().shape({
      firstName: yup
        .string()
        .required("First Name is required")
        .matches(
          commonRegex.firstNameOrLastName,
          "Special characters not allowed"
        ),
      lastName: yup
        .string()
        .required("Last Name is required")
        .matches(
          commonRegex.firstNameOrLastName,
          "Special characters not allowed"
        ),
      phoneNumber: yup.string().required("Phone Number is required"),
      email: yup
        .string()
        .required("Email is required")
        .email("Enter a valid email")
    }),
    address1: yup.string().required("Address Line 1 is required"),
    city: yup.string().required("City Name is required"),
    state: yup.string().required("State Name is required"),
    postalCode: yup.string().required("Zip code is required"),
  });

  const handleFormSubmit = (
    customer: customerRegistration,
    setFieldError: any,
    setSubmitting: any
  ) => {
    customerStore
      .createCustomer(customer)
      .then((response) => {
        if (response !== null) {
          setSubmitting(false);
          customer.customerId = response.customerId;
          props.SetCustomer(response.customerId);
          props.onNextClick("mowers");
          props.customerValue(customer);
          //toast.success("Success!", { theme: "colored" });
          //navigate("/registrationSuccess");
        }
      })
      .catch((err) => {
        logger.log(err);
        let msg: string = "";
        if (err.length && err.length > 0) {
          err.forEach((i: { [x: string]: string }) => {
            msg += i["ErrorMessage"] + " ";
          });

          setErrMsg(msg);
        }
        toast.error("There was an issue completing the registration.", {
          theme: "colored",
        });
      });
    setSubmitting(false);
  };

  if (isLoading) return <Loading content="Loading user info..." />;

  
  return (
    <>
      {!isAuthenticated ? (
        <></>
      ) : (
        <>
          
          <Card.Content>
            <Segment clearing>
              <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={customer}
                onSubmit={(values, { setFieldError, setSubmitting }) => {
                  return handleFormSubmit(values, setFieldError, setSubmitting);
                }}
              >
                {({ handleSubmit, isSubmitting, isValid }) => (
                  <Form
                    className="oso-form"
                    onSubmit={handleSubmit}
                    autoComplete="Off"
                  > 
                    <h3>Primary Contact</h3>
                    <TextInput
                        placeholder=""
                        name="customerName"
                        label="Company Name"
                        maxLength={100}
                        required
                    />
                    <Grid
                        columns={4}
                    >
                    <GridColumn style={{ flex: "1" }}>
                        <TextInput
                            placeholder=""
                            name="primaryUser.firstName"
                            label="First Name" 
                            required
                            maxLength={50}
                        />
                    </GridColumn>
                    <GridColumn style={{ flex: "1" }}>
                        <TextInput
                            placeholder=""
                            name="primaryUser.lastName"
                            label="Last Name" 
                            required
                            maxLength={50}
                        />
                    </GridColumn>
                    <GridColumn style={{ flex: "1" }}>
                        <TextInput
                        placeholder=""
                        name="primaryUser.phoneNumber"
                        label="Phone Number"
                        required
                        maxLength={16}
                        />
                    </GridColumn>
                    <GridColumn style={{ flex: "1" }}>
                        <TextInput
                        placeholder=""
                        name="primaryUser.email"
                        label="Email"
                        required
                        readOnly
                        maxLength={256}
                        />
                    </GridColumn>
                    </Grid>

                    <h3>Secondary Contact</h3>
                    <Grid
                        columns={4}
                    >
                    <GridColumn style={{ flex: "1" }}>
                        <TextInput
                            placeholder=""
                            name="secondaryUser.firstName"
                            label="First Name" 
                            maxLength={50}
                        />
                    </GridColumn>
                    <GridColumn style={{ flex: "1" }}>
                        <TextInput
                            placeholder=""
                            name="secondaryUser.lastName"
                            label="Last Name" 
                            maxLength={50}
                        />
                    </GridColumn>
                    <GridColumn style={{ flex: "1" }}>
                        <TextInput
                        placeholder=""
                        name="secondaryUser.phoneNumber"
                        label="Phone Number"
                        maxLength={16}
                        />
                    </GridColumn>
                    <GridColumn style={{ flex: "1" }}>
                        <TextInput
                        placeholder=""
                        name="secondaryUser.email"
                        label="Email"
                        maxLength={256}
                        />
                    </GridColumn>
                    </Grid>

                    <h3>Address</h3>
                    <TextInput
                        placeholder=""
                        name="address1"
                        label="Address Line 1"
                        maxLength={200}
                        required
                    />
                    <TextInput
                        placeholder=""
                        name="address2"
                        label="Address Line 2 (Optional)"
                        maxLength={200}
                    />
                    <Grid
                        columns={3}
                    >
                    <GridColumn style={{ flex: "1" }}>
                        <TextInput
                            placeholder=""
                            name="city"
                            label="City" 
                            required
                            maxLength={50}
                        />
                    </GridColumn>
                    <GridColumn style={{ flex: "1" }}>
                        
                        <SelectInput 
                        name="state"
                        label="State"
                        options={stateOptions}
                        placeholder=""
                        />
                    </GridColumn>
                    <GridColumn style={{ flex: "1" }}>
                        <TextInput
                        placeholder=""
                        name="postalCode"
                        label="Zip Code"
                        required
                        maxLength={16}
                        />
                    </GridColumn>
                    </Grid>

                    <br/>
                    
                    <Button
                    className="large primary-bg-color"
                    disabled={!isValid}
                    loading={isSubmitting}
                      positive
                      type="submit"
                      content="Next"
                    />
                  </Form>
                )}
              </Formik>
            </Segment>
          </Card.Content>

          {errMsg === "" ? (
            <></>
          ) : (
            <>
              <Card.Content>
                <Message
                  error
                  icon="question circle"
                  header="User Registration Failed."
                  content={
                    "Please review the following errors and fix them to continue. " +
                    errMsg
                  }
                />
              </Card.Content>
            </>
          )}
        </>
      )}{" "}
    </>
  );

}

export default observer(CustomerRegistrationPage);