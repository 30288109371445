import React, { useEffect, useState } from "react";
import { useDocumentTitle } from "../../app/common/page-title";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { CLAIM_OSO_APP_COMPANY_ID, CLAIM_OSO_APP_USER_ID } from "../../types/constants";
import { observer } from "mobx-react-lite";
import { MenuItem, Tab, TabPane } from "semantic-ui-react";
import CustomerRegistrationPage from "./CustomerRegistrationPage";
import AddMower from "../mower-registration/AddMower";
import { customerRegistration } from "../../types/registration/customerRegistration";

const RegistrationPage = () => {

    useDocumentTitle("Register");
    const { isAuthenticated, isLoading, getAccessTokenSilently, user } =
        useAuth0();
    const navigate = useNavigate();
    const [isAddMowerDisable, setAddMowerState] = useState(true);
    const [customerId, setCustomerId] = useState(0);
    const [activeItem, setActiveState] = useState("account");
    const [customer, setCustomerValue] = useState<customerRegistration>(new customerRegistration());

    const color = "blue";

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }

        if (isAuthenticated) {
            if (
                user![CLAIM_OSO_APP_USER_ID] !== undefined ||
                user![CLAIM_OSO_APP_COMPANY_ID] !== undefined
            ) {
                navigate("/");
                return;
            }
        }
    }, []);

    const SetCustomer = (id: number) => {
      setCustomerId(id);
      setAddMowerState(false);
    }

    const customerValue = (customer: customerRegistration) =>{
      setCustomerValue(customer);
    }

    const handleItemClick = (e: any, { name }: any) => {
      setActiveState(name);
    }

    const onBackClick =(name: any) =>{
      setActiveState(name);
      console.log(customer);
    }

    const panes = [
      {
        menuItem: (
          <MenuItem 
            key='account' 
            style={{width: "50%", height:"75px", fontSize:"20px" }}
            name="account"
            className="light-grey"
            active={activeItem === 'account'}
            onClick={handleItemClick}
          >
            1 &nbsp;&nbsp;  Enter Account Details
          </MenuItem>
        ),
      },
      {
        menuItem: (
          <MenuItem 
            key='mowers'
            disabled={isAddMowerDisable} 
            style={{width: "50%", height:"75px", fontSize:"20px" }}
            name="mowers"
            className="light-grey"
            active={activeItem === 'mowers'}
            onClick={handleItemClick}
          >
            2 &nbsp;&nbsp; Add Your Mowers
          </MenuItem>
        ),
      }
    ]

    return (
        <>
          {!isAuthenticated ? (
            <></>
          ) : (
            <>
              <div style={{ backgroundColor: "#f5f5f5" }}>
                <div className="registration-top-card">
                  <p>
                  Register Your Mower
                  </p>
                  
                </div>
                <div style={{ padding: "30px" }}>
                  <Tab menu={{ pointing: true, color }} panes={panes} />
                  <br/>
                  {activeItem == "account" ? (<>
                  <CustomerRegistrationPage 
                    SetCustomer={SetCustomer}
                    onNextClick={onBackClick}
                    customerValue={customerValue}
                    customerValueDefault={customer}
                  />
                </>) : (<>
                  <AddMower 
                    customerId={customerId} 
                    onBackClick={onBackClick}
                  />
                  </>)
                }
                </div>
                
              </div>

            </>
          )}{" "}
        </>
      );

}

export default observer(RegistrationPage);