import React, { useState } from "react";
import { Button, Icon, Label, List } from "semantic-ui-react";
import { toHoursOrMinutes } from "../../shared/utils";
import moment from "moment";
import Loading from "../../app/layout/loading";
import { Exception } from "../Exception";
import { GoToTop } from "./GoToTop";

const MowerActivityRenderMowerActivityList = (props: any) => {
  if (
    props?.mowerActivities?.mowerActivities?.results?.length === 0 ||
    props?.mowerActivitiesByDate === undefined ||
    props?.mowerActivitiesByDate?.get(props.date)?.length === 0
  ) {
    return <></>;
  }

  return (
    <List.Item>
      <List
        relaxed
        style={{
          paddingLeft: props.paddingLeft,
          paddingRight: props.paddingRight,
        }}
        className="mower-list"
      >
        {props.mowerActivitiesByDate
          ?.get(props.date)
          ?.map((activity: any, index: number) => {
            return (
              <List.Item
                className={
                  "mower-list-item " +
                  (props.highlightedLocationIndex === index
                    ? "selected-mower-location"
                    : "")
                }
                key={props.date + "-" + activity.mowerId + "-" + index}
              >
                <List.Icon size="large" verticalAlign="top">
                  <Icon
                    circular
                    size="mini"
                    className="icon-grey-circle"
                  ></Icon>
                </List.Icon>
                <List.Content>
                  <List.Description className="mower-list-item-desc">
                    <div className="mower-list-item-title">
                      {activity &&
                      activity.streetNumber &&
                      activity.streetName ? (
                        <>
                          <div>
                            {activity.streetNumber} {activity.streetName},
                          </div>
                          <div>
                            {activity.city}, {activity.state} {activity.zipCode}
                          </div>
                        </>
                      ) : (
                        "Location not available"
                      )}
                    </div>
                    <div>
                      Arrived at {props.moment(activity.startTime).format("h:mm a")}
                    </div>
                    {activity.status == "Active" ?
                      (
                        <>
                          <div>
                            Time at location: {toHoursOrMinutes(activity.onTime?.toString())}
                            <span>&nbsp; •&nbsp; Cutting for </span>{toHoursOrMinutes(activity.cutTime?.toString())}
                          </div>
                        </>
                      ) :
                      (
                        <>
                          <div>
                            Time at location: {toHoursOrMinutes(activity.onTime?.toString())}
                            <span>&nbsp; •&nbsp; Cut Time: </span>{toHoursOrMinutes(activity.cutTime?.toString())}
                          </div>
                        </>
                      )
                    }
                    
                  </List.Description>
                </List.Content>
              </List.Item>
            );
          })}
      </List>
    </List.Item>
  );
};

const ShowResultCounts = (props: any) => {
  if (
    props.mowerActivities === undefined ||
    props.currentPage === 0 ||
    props.mowerActivities?.mowerActivities === undefined ||
    props.mowerActivities?.pagination?.rowCount === 0 ||
    isNaN(
      props.mowerActivities?.pagination?.currentPage *
        props.mowerActivities?.pagination?.pageSize
    )
  ) {
    return <></>;
  }

  let current =
    props.mowerActivities?.pagination?.currentPage *
    props.mowerActivities?.pagination?.pageSize;
  let total = props.mowerActivities?.pagination?.rowCount;

  if (total === undefined || total === 0 || current > total) {
    return <></>;
  }

  return (
    <Label
      basic
      className="no-border"
      style={{ paddingTop: "10px", position: "relative", left: "37%" }}
    >
      {current}&nbsp;of&nbsp;{total}&nbsp;
    </Label>
  );
};

export const MowerActivityList = (props: any) => {
  const [isDisplayError, setErrorMessage] = useState(true);
  if(props.error){
    return(
      <>
        <Exception 
          error={props.error} 
        />
      </>
    );
  }

  if (
    !props.isDataLoading &&
    (!props.mowerActivities ||
      !props.mowerActivities.mowerActivities ||
      props.mowerActivities?.mowerActivities?.length === 0 ||
      props.mowerActivitiesByDate === undefined)
  ) {
    return <>No mower activity found for the selected date range.</>;
  }
  return (
    <>
      <List
        relaxed
        style={{
          paddingLeft: props.paddingLeft,
          paddingRight: props.paddingRight,
        }}
        className="scroll-container"
      >
        {Array.from(props.mowerActivitiesByDate?.keys())?.map(
          (dateKey: any, index: number) => {
            let isThisYearActivity = moment(new Date()).format("YYYY") == moment(dateKey).format("YYYY") 
              ? true: false;
            return (
              <React.Fragment key={"date" + dateKey + "-x-" + index}>
                <List.Item className="mower-list-item">
                  <div className="mower-activity-date-title pt-s">
                    <Icon circular inverted color="grey" name="calendar outline" />
                    {isThisYearActivity ? moment(dateKey).format("ddd. MMM D") : moment(dateKey).format("ddd. MMM D, YYYY")}
                  </div>
                </List.Item>
                <MowerActivityRenderMowerActivityList
                  date={dateKey}
                  mowerActivities={props.mowerActivities}
                  mowerActivitiesByDate={props.mowerActivitiesByDate}
                  highlightedLocationIndex={props.highlightedLocationIndex}
                  moment={props.moment}
                />
              </React.Fragment>
            );
          }
        )}
      </List>
      {props.isDataLoading ? (
        <Loading content="Loading..." inline={true} size="small" />
      ) : (
        <>
          <Button
            style={{
              display:
                props.currentPage > 0 && props.currentPage < props.lastPage
                  ? "show"
                  : "none",
              paddingRight: "20px !important",
              paddingLeft: "10Px",
              marginLeft: "50px",
              color: "#5E85C4 !important",
              width: "270px"
            }}
            className="oso-button-color"
            onClick={props.LoadMoreMowerActivities}
          >
            SEE MORE
          </Button>
        </>
      )}
      {props.mowerActivities?.mowerActivities?.length>10 ?(
        <GoToTop />
      ) : (
        <></>
      )}

      <ShowResultCounts
        mowerActivities={props.mowerActivities}
        currentPage={props.currentPage}
      />
      <br />
    </>
  );
};
