import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import mowerReducer from "./mower/mowerSlice";

export const rootStore = configureStore({
  reducer: {
    user: userReducer,
    mower: mowerReducer
  },
});

export type RootState = ReturnType<typeof rootStore.getState>;
export type AppDispatch = typeof rootStore.dispatch;
