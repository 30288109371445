import { observer } from "mobx-react-lite";
import { MowerDetailsEnter } from "./MowerDetailsEnter";
import { MowerRegistration } from "../../types/registration/mowerRegistration";

const AddMowerManually = (props: any) => {
  const onChangeMowerDetails = (mower: MowerRegistration) => {
    props.onChangeMowerDetails(mower);
  }
  return (
    <>
      <div>
        <div className="ui">
          <img className="ui centered medium image" src="/assets/images/mower.png" />
        </div>

        <div className="ui one cards" style={{ paddingLeft: '20%', paddingRight: '20%' }}>
          <div className="card light-grey">
            <div className="center aligned content">
              Locate the sticker on back of the red dial.
            </div>
          </div>
        </div>
        <br />
        <MowerDetailsEnter onChangeMowerDetails={onChangeMowerDetails} />
      </div>
    </>
  );
}

export default observer(AddMowerManually); 