import { useAuth0 } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, CardHeader, Grid, Header, Icon, Modal, ModalActions, ModalContent, ModalHeader, Segment } from "semantic-ui-react";
import { logger } from "../../app/common/logger";
import { useDocumentTitle } from "../../app/common/page-title";
import {
  AUTO_REFRESH_INTERVAL_HOME_PAGE,
  CLAIM_OSO_APP_COMPANY_ID,
  CLAIM_OSO_APP_USER_ID,
  LOCAL_STORAGE_DEFAULT_MAP_BOUNDS,
  LOCAL_STORAGE_HOME_PAGE_MAP_BOUNDS,
  LOCAL_STORAGE_SEL_CUSTOMER,
  LOCAL_STORAGE_SEL_CUSTOMER_NAME,
} from "../../types/constants";
import {
  MowersSummaryQuery,
  MowersSummaryResponse,
} from "../../types/mower/dashboard";
import { useStore } from "../../app/stores/store";
import { getCustomerId } from "../../shared/utils";
import NavBar from "../../app/layout/nav-bar";
import { MapWithMarkers } from "../map/MapWithMarkers";
import MowerList, { MowerListSummary } from "./MowerList";
import { MowerActivity } from "../../types/mower/mower-activity";
import MowersStats from "./MowersStats";
import Loading from "../../app/layout/loading";
import { MapMarkerProps } from "../map/MapMarkers";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { Exception } from "../Exception";
import { OsoUser } from "../../types/registration/user";
import { getCurrentUser as getUser } from "../../store/user/userSlice";
import { AcceptTermsAndConditionsCmdInput } from "../../types/registration/accept-terms-and-conditions";
import { toast } from "react-toastify";

const HomePage = () => {
  const { isAuthenticated, user, logout } = useAuth0();
  const navigate = useNavigate();
  const handleMowerClick = (mowerId: string) => {
    if(selector.user?.isSubscribed){
      navigate("/mower-activity/" + mowerId);
    }
  };

  useDocumentTitle("Home");

  const { mowerStore, userStore } = useStore();
  const { getAllMowersActivity } = mowerStore;
  const [mowers, setMowers] = useState(new MowersSummaryResponse());
  const [showStats, setShowStats] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [highlightedLocationIndex, SetHighlightedLocationIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState("");
  const [mapMarkers, setMapMarkers] = useState<MapMarkerProps[]>([]);
  const [osoUser, setOsoUser] = useState<OsoUser>(new OsoUser());
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.user);
  const size = "fullscreen";
  const [open, setOpen] = useState(false);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const isMobile = width <= 768;

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (selector.isSuccess && selector.user) {
      setOsoUser(selector.user);
      if(osoUser.isTermsConditionsAccepted){
        setOpen(false);
      } else {
        setOpen(true);
      }

    } else {
      dispatch(getUser());
    }
  }, [selector.isSuccess]);

  useEffect(() => {
    setShowStats(true);
    SetHighlightedLocationIndex(-1);

    if (isAuthenticated) {
      console.log(`HomePage: ${user}`);
      if (
        user![CLAIM_OSO_APP_USER_ID] === undefined ||
        user![CLAIM_OSO_APP_COMPANY_ID] === undefined
      ) {
        navigate("/customer-registration");
        return;
      }
      var value = user?.email_verified;
      const controller = new AbortController();
      let customerId = getCustomerId(user![CLAIM_OSO_APP_COMPANY_ID]);

      localStorage.removeItem(LOCAL_STORAGE_HOME_PAGE_MAP_BOUNDS);
      localStorage.removeItem(LOCAL_STORAGE_DEFAULT_MAP_BOUNDS);
      let input = new MowersSummaryQuery(customerId);
      fetchAllMowerActivity(input, controller);
      const intervalId = setInterval(() => {
        fetchAllMowerActivity(input, new AbortController());
      }, AUTO_REFRESH_INTERVAL_HOME_PAGE);

      return () => {
        localStorage.removeItem(LOCAL_STORAGE_HOME_PAGE_MAP_BOUNDS);
        localStorage.removeItem(LOCAL_STORAGE_DEFAULT_MAP_BOUNDS);
        clearInterval(intervalId);
        controller.abort();
      };
    }
  }, []);


  const fetchAllMowerActivity = (input: MowersSummaryQuery, controller: AbortController) => {
    setFetchError("");
    getAllMowersActivity(input, controller.signal)
      .then(function (res) {
        setIsLoading(false);
        if (res?.mowerSummaries?.length > 0) {
          const markers: MapMarkerProps[] = [];
          for (let i = 0; i < res.mowerSummaries.length; i++) {
            if (
              res.mowerSummaries[i].lastBladeStatus.toLowerCase() !== "offline"
            ) {
              let mowerTitle = res.mowerSummaries[i].mowerTitle;
              if (!res.mowerSummaries[i].mowerTitle) {
                mowerTitle = res.mowerSummaries[i].mowerName;
              }
              const marker: MapMarkerProps = {
                latitude: res.mowerSummaries[i].latitude,
                longitude: res.mowerSummaries[i].longitude,
                color: getStatusIconColor(
                  res.mowerSummaries[i].lastBladeStatus
                ),
                iconLetter: mowerTitle.charAt(0),
              };
              markers.push(marker);
            }
          }

          let sortOrder = ["Active", "On", "Offline"];
          res.mowerSummaries.sort((a, b) => {
            return (
              sortOrder.indexOf(a.lastBladeStatus) -
              sortOrder.indexOf(b.lastBladeStatus)
            );
          });
          setMowers(res);
          setMapMarkers(markers);
        }
      })
      .catch((err) => {
        logger.error("Please verify API is up and running.", err);
        setFetchError(err.message);
      });
  }

  const getStatusIconColor = (status: string): string => {
    if (!status) return "grey";
    if (status.toLowerCase() === "on") return "blue";
    if (status.toLowerCase() === "active") return "green";
    return "grey";
  };

  const RenderHeading = () => {
    return <div className="homePage-title-level-2"><Icon circular inverted color="grey" name="chart bar outline" />Today's Activity</div>;
  };

  const handleAcceptTermsAndCondition = () =>{
    var accpet = new AcceptTermsAndConditionsCmdInput(selector.user ? selector.user?.userId : 0)
    userStore
      .acceptTermsAndConditions(accpet)
      .then((response) => {
        if (response !== null) {
          if(response.done){
            setOpen(false);
            dispatch(getUser());
          }
        }
      })
      .catch((err) => {
        logger.log(err);
        toast.error("There was an issue in accepting terms and conditions.", {
          theme: "colored",
        });
      });
  }
  
  const handleResendAuth0EmailVerification = () =>{
    userStore.resendAuth0VerificationEmail().then();
  }

  const toMowerListSummary = (
    mowerActivity: MowerActivity
  ): MowerListSummary => {
    return {
      mowerId: mowerActivity.mowerId,
      mowerName: mowerActivity.mowerName,
      mowerTitle: mowerActivity.mowerTitle,
      lastBladeStatus: mowerActivity.lastBladeStatus,
      onTime: mowerActivity.onTime,
      cutTime: mowerActivity.cutTime,
      location: mowerActivity.location,
      serialNumber: mowerActivity.serialNumber,
      streetNumber: mowerActivity.streetNumber,
      streetName: mowerActivity.streetName,
      city: mowerActivity.city,
      state: mowerActivity.state,
      zipCode: mowerActivity.zipCode,
      cutTimeAtLastLocation: mowerActivity.cutTimeAtLastLocation
    };
  };

  const callOnOpen = () => {
    setMenuOpen(true);
    setShowStats(false);
  };

  const callOnClose = () => {
    setMenuOpen(false);
    setShowStats(true);
  };

  const GlobalException = (props: any) => {
    if (props.error) {
      return (
        <Segment placeholder>
          <Header icon>
            <Icon name="exclamation triangle" color="red" />
            <Exception error={props.error} />
          </Header>
        </Segment>
      );
    } else {
      return (<></>);
    }
  };

  const handleLogout = () => {
    if (localStorage.getItem(LOCAL_STORAGE_SEL_CUSTOMER_NAME)) {
      localStorage.removeItem(LOCAL_STORAGE_SEL_CUSTOMER_NAME);
    }
    if (localStorage.getItem(LOCAL_STORAGE_SEL_CUSTOMER)) {
      localStorage.removeItem(LOCAL_STORAGE_SEL_CUSTOMER);
    }
    logout({ returnTo: window.location.origin });
  };

  const UpgradePlan = () => {
    return (<>
      <div className="ui middle aligned grid">
        <div className="eight column wide">
          <div className="ui cards">
            <div className="ui card centered" style={{ borderRadius: "10px" }}>
              <div className="content">
                <div className="header" style={{ textAlign: "center" }}>Upgrade Your Plan</div>

                <div className="description" style={{ textAlign: "center" }}>
                  Please upgrade your plan to see the how many mowers are actively cutting, your daily cut time, and more.
                </div>
                <br />
                <div className="vertical-center">
                  <Button className="ui black-bg-color" onClick={() =>
                    window.open("https://www.osoelectric.com/contact/", "_blank")}> Upgrade Now</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>);
  }

  const TermsAndCondition = () =>{
    return (<><Modal
      size={size}
      open={open}
    >
      <ModalHeader>
        <div className="header" style={{ textAlign: "center" }}>Oso App Terms & Conditions</div>
      </ModalHeader>
      <ModalContent>
        <p>Oso Electric Equipment, LLC (“Oso”) is providing a free, promotional, trial, or evaluation license to access Oso App Subscription Service for sixty (60) days. You agree to use Oso App Subscription Service and the Equipment or other Services solely for evaluation purposes and in compliance with the restrictions and other provisions in the Terms and Conditions. A credit card is required to be placed on file for the Oso App Subscription Service. At the end of the sixty (60) day period, you will automatically transition into a paid subscription but may cancel at any time upon thirty (30) days’ notice, which shall effectuate the termination for the Oso App Subscription Service on new recurring payment.</p>
      </ModalContent>
      <ModalActions>
        <div className="vertical-center">
          <Button className="ui black-bg-color" onClick={handleAcceptTermsAndCondition}>Accept</Button>
        </div>
      </ModalActions>
    </Modal></>)
  }

  const VerifyEmail = () =>{
    return (<><Modal
      size={size}
      open={true}
    >
      <ModalHeader>
        <div className="header" style={{ textAlign: "center" }}>Please verify your email address</div>
      </ModalHeader>
      <ModalContent>
        <p>Welcome to Oso! Before you get started, please verify your email. Check your inbox for the verification link. For further assistance, contact support here.</p>
      </ModalContent>
      <ModalActions>
        <div className="vertical-center">
          <Button className="ui primary-bg-color" onClick={handleResendAuth0EmailVerification}>Resend Email</Button>
          <Button className="ui black-bg-color" onClick={handleLogout}>Log out</Button>
        </div>
      </ModalActions>
    </Modal></>)
  }

  const DesktopView = () => {
    if (fetchError) {
      return (
        <>
          <GlobalException
            error={fetchError}
          />
        </>
      );
    }
    if (isLoading)
      return (
        <Loading content="Please wait while we fetch your data."></Loading>
      );
    
    return (
      <>
        {isLoading ||
          !isAuthenticated ? (
          <></>
        ) : (
          <>{!user?.email_verified ? (<><VerifyEmail /></>) : (
          
          <>
            <Grid
              columns={2}
              style={{
                height: "100%",
                width: "100%",
                paddingRight: "0px",
                margin: "0px",
              }}
            >
              <Grid.Column
                style={{
                  maxWidth: "425px",
                  paddingLeft: "20px",
                  height: "100vh",
                  overflowY: "scroll",
                  overflowX: "hidden"
                }}
              >
                <NavBar
                  menuOpen={menuOpen}
                  callOnOpen={callOnOpen}
                  callOnClose={callOnClose}
                />
                {showStats ? <RenderHeading /> : <></>}
                <br />
                {showStats &&
                  mowers.mowerSummaries &&
                  mowers.mowerSummaries.length !== 0 ? (
                  <MowersStats
                    activeMowersCount={mowers.activeMowers}
                    totalMowersCount={mowers.totalMowers}
                    totalHoursSpentMowing={mowers.hoursSpentMowing}
                    averageCutTime={mowers.averageCutTime}
                    user={selector.user}
                  />
                ) : (
                  <></>
                )}{" "}
                <br />
                {showStats ? (
                  <MowerList
                    title="Mowers"
                    highlightedLocationIndex={highlightedLocationIndex}
                    callback={handleMowerClick}
                    user={selector.user}
                    mowers={mowers.mowerSummaries?.map((mower) =>
                      toMowerListSummary(mower)
                    )}
                  />
                ) : (
                  <></>
                )}
                {mowers?.mowerSummaries?.length === 0 || mowers?.mowerSummaries == undefined ? (
                  <>
                    <Button className="ui black-bg-color" onClick={() => navigate("/add-mower")}>Add Your Mowers</Button>
                  </>) : (<></>)
                }
              </Grid.Column>
              <Grid.Column
                style={{
                  width: "100%",
                  flex: "1",
                  height: "100vh",
                  padding: "0px",
                }}
              >
                {selector.user?.isSubscribed ? (<>
                  <MapWithMarkers
                    markers={mapMarkers}
                    SetHighlightedLocationIndex={SetHighlightedLocationIndex}
                    localStorageMapBound={LOCAL_STORAGE_HOME_PAGE_MAP_BOUNDS}
                  />
                </>) : (<>
                  <div className="upgrade-plan">
                    <UpgradePlan />
                  </div>
                </>)}
              </Grid.Column>
            </Grid>
          </>
          )}</>
        )}

        {!selector.user?.isTermsConditionsAccepted ?
        (<><TermsAndCondition /></>) : (<></>)}
      </>
    );

  };

  const MobileView = () => {
    if (fetchError) {
      return (
        <>
          <GlobalException
            error={fetchError}
          />
        </>
      );
    }
    return (
      <>
        {isLoading ||
          !isAuthenticated ? (
          <></>
        ) : (
          <>
            {selector.user?.isSubscribed ? (<>
              <div className="PanelContent">
                <MapWithMarkers
                  markers={mapMarkers}
                  SetHighlightedLocationIndex={SetHighlightedLocationIndex}
                  localStorageMapBound={LOCAL_STORAGE_HOME_PAGE_MAP_BOUNDS}
                />{" "}
              </div></>) : (<>
                <div className="upgrade-plan-m-view">
                  <UpgradePlan />
                </div>
              </>)}
            <br />
            <div className="PanelContent" style={{ paddingLeft: "10px" }}>
              <NavBar
                menuOpen={menuOpen}
                callOnOpen={callOnOpen}
                callOnClose={callOnClose}
              />
              {showStats ? <RenderHeading /> : <></>} <br />
              {showStats &&
                mowers.mowerSummaries &&
                mowers.mowerSummaries.length !== 0 ? (
                <MowersStats
                  activeMowersCount={mowers.activeMowers}
                  totalHoursSpentMowing={mowers.hoursSpentMowing}
                  totalMowersCount={mowers.totalMowers}
                  averageCutTime={mowers.averageCutTime}
                  user={selector.user}
                />
              ) : (
                <></>
              )}{" "}
              <br />
              {showStats ? (
                <MowerList
                  title="Mowers"
                  highlightedLocationIndex={highlightedLocationIndex}
                  callback={handleMowerClick}
                  user={selector.user}
                  mowers={mowers.mowerSummaries?.map((mower) =>
                    toMowerListSummary(mower)
                  )}
                />
              ) : (
                <></>
              )}
                {mowers?.mowerSummaries?.length === 0 || mowers?.mowerSummaries == undefined ? (
                  <>
                    <Button className="ui black-bg-color" onClick={() => navigate("/add-mower")}>Add Your Mowers</Button>
                  </>) : (<></>)
                }
            </div>
          </>
        )}
        {!selector.user?.isTermsConditionsAccepted ?
        (<><TermsAndCondition /></>) : (<></>)}
      </>
    );
  };

  return <>{isMobile ? <MobileView /> : <DesktopView />}</>;
};

export default observer(HomePage);
