import { useState } from "react";

export interface Config {
  env: "dev" | "test" | "prod";
  auth0Domain: string;
  auth0Audience: string;
  auth0ClientId: string;
  apiBaseUrl: string;
  resultPageSize: number;
  apiDefaultTimeoutInSeconds: number;
}

export const defaultConfig: Config = {
  env: "dev",
  auth0Domain: "oso-eletric.us.auth0.com",
  auth0Audience: "http://localhost:5000/api/",
  auth0ClientId: "mE6pnTiZeSBlzajrK6j9EMwhcKGzX48M",
  apiBaseUrl: "http://localhost:5000/api/",
  resultPageSize: 15,
  apiDefaultTimeoutInSeconds: 30,
};

export const configUrl = "/configuration.json";
