import { useField } from "formik";
import React from "react";
import { Form, Label } from "semantic-ui-react";

interface Props {
  placeholder: string;
  name: string;
  label?: string;
  type?: string;
  maxLength?: number;
  readOnly?: boolean;
  width?: number;
  color?: string;
  required?: boolean;
  transparent?: boolean;
  value?: string;
}

const TextInput = (props: Props) => {
  const [field, meta] = useField(props.name);
  return (
    <Form.Field error={meta.touched && !!meta.error} required={props.required}>
      <label htmlFor={props.name}>{props.label}</label>
      <input id={props.name} {...field} {...props} />
      {meta.touched && meta.error ? (
        <Label basic color="red" pointing="above">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
};
export default TextInput;
