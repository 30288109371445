import { Config, defaultConfig } from "./config";
import React, { useContext, useState, createContext, FC } from "react";

type ConfigContextState = {
  config: Config;
  setConfig: (newConfig: Config) => void;
};

const contextDefaultValues: ConfigContextState = {
  config: defaultConfig,
  setConfig: () => {},
};

export const ConfigContext =
  createContext<ConfigContextState>(contextDefaultValues);

// @ts-ignore
const ConfigProvider = ({ children }) => {
  const [configState, setConfigState] = useState(defaultConfig);

  return (
    <ConfigContext.Provider
      value={{
        config: configState,
        setConfig: setConfigState,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
