export interface MowerRegistration {
    customerId: string;
    mowerName: string;
    serialNumber: string
    isQrScan: boolean
}

export interface MowerRegistrationCmdOutput {
    done: boolean;
}

export interface MowerRegistrationCmdInput{
    mowers: Array<MowerRegistration>;
}

export class MowerRegistrationCmdInput implements MowerRegistrationCmdInput{
    constructor(init?: Array<MowerRegistrationCmdInput>) {
        if (init !== undefined) {
            Object.assign(this, init);
        }
    }
}


export class MowerRegistration implements MowerRegistration {
    constructor(init?: MowerRegistration) {
        if (init !== undefined) {
            Object.assign(this, init);
        } else {
            this.customerId = "";
            this.mowerName = "";
            this.serialNumber = "";
            this.isQrScan = false
        }
    }
}