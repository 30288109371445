import { Button } from "semantic-ui-react";


export const Exception = (props: any) => {
    if (props.error && props.error.includes("401")) {
        return (
            <>
                You are not authorized to access this data.
            </>
        );
    } else if (props.error) {
        return (
            <>
                <div>
                    Oops, something went wrong. If this issue continues, please contact Oso support.
                </div>
                <br />
                <Button className="mower-save" onClick={() =>
                    window.open("https://www.osoelectric.com/contact/", "_blank")
                }>
                    CONTACT SUPPORT
                </Button>
            </>
        );
    } else {
      return(<></>);
    }
  };
  