import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { MowersSummaryQuery } from "../../types/mower/dashboard";
import { ChangeMowerTitleCmdInput } from "../../types/mower/update-mower-title-models";
import { MowerActivityQryInput } from "../../types/mower/mower-activity-query";
import { MowerRegistration } from "../../types/registration/mowerRegistration";
import { MowerValidateQryInput } from "../../types/mower/mower-validate-query";

export default class MowerStore {
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  getAllMowerList = async (input: MowersSummaryQuery, signal?: any) => {
    return await agent.Mowers.getAllMowerList(input, signal);
  };

  getAllMowersActivity = async (input: MowersSummaryQuery, signal?: any) => {
    return await agent.Mowers.getAllMowersActivity(input, signal);
  };

  getMowerActivity = async (input: MowerActivityQryInput, signal?: any) => {
    return await agent.Mowers.getMowerActivity(input, signal);
  };

  updateMowerTitle = async (input: ChangeMowerTitleCmdInput, signal?: any) => {
    return await agent.Mowers.updateMowerTitle(input, signal);
  };

  addMower = async (input: Array<MowerRegistration>, signal?: any) => {
    return await agent.Mowers.addMower(input, signal);
  };

  validateMower = async (input: MowerValidateQryInput, signal?: any) =>{
    return await agent.Mowers.validateMower(input, signal);
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
}
