export interface MowerValidateQryInput {
    serialNumber: string;
}

export class MowerValidateQryInput implements MowerValidateQryInput {
    serialNumber: string;
    constructor(serialNumber: string) {
        this.serialNumber = serialNumber;
    }
}

export interface MowerValidateQryOutput {
    isValid: boolean;
}