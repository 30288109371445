import React from "react";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.min.css";
import { store, StoreContext } from "./app/stores/store";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./report-web-vitals";
import App from "./components/App";
import { Provider as ReduxProvider } from "react-redux";
import { rootStore } from "./store/rootStore";
import ConfigProvider from "./useConfig";
import "@fontsource/raleway";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ConfigProvider>
    <StoreContext.Provider value={store}>
      <ReduxProvider store={rootStore}>
        <Router>
          <App />
        </Router>
      </ReduxProvider>
    </StoreContext.Provider>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
