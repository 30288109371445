import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Segment, Image, Grid } from "semantic-ui-react";
import { useDocumentTitle } from "../app/common/page-title";

const SplashPage = () => {
  useDocumentTitle("Splash");

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/home");
    }, 3000);
  }, []);

  return (
    <>
      <Segment placeholder vertical className="masthead">
        <Grid relaxed textAlign="center" columns="equal">
          <Grid.Row verticalAlign="middle">
            <Grid.Column>
              <Container text textAlign="center">
                <Image src="/assets/images/lawn-mower.png" alt="logo" />
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
};

export default observer(SplashPage);
