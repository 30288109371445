export interface OsoUser {
  userId: number;
  customerId: string;
  customerName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isSubscribed: boolean;
  isTermsConditionsAccepted: boolean
}

export class OsoUser implements OsoUser {
  constructor(init?: OsoUser) {
    if (init !== undefined) {
      Object.assign(this, init);
    } else {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phoneNumber = "";
    }
  }
}

export class UserFormValues {
  user: OsoUser = new OsoUser();
  constructor(init?: UserFormValues) {
    this.user = new OsoUser(init?.user);
  }
}

export class UserRegistrationValues {
  userId: number = 0;
}
