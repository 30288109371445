import { Icon } from "semantic-ui-react";
import React from "react";

interface Props {
  statusText: string;
}
const MowerStatusIcon = ({ statusText }: Props) => {
  if (!statusText) return <Icon name="circle" size="small" color="grey"></Icon>;

  const status = statusText.trim().toLowerCase();
  if (status === "active")
    return <Icon name="circle" size="small" color="green"></Icon>;
  else if (status === "on")
    return <Icon name="circle" size="small" color="blue"></Icon>;

  return <Icon name="circle" size="small" color="grey"></Icon>;
};

export default MowerStatusIcon;
