import { useState } from "react";
import { ViewFinder } from "./ViewFinder";
import { QrReader } from "react-qr-reader";

export const QrScan = (props: any) =>{
    const handleQrReader = (result: any, error: any) => {
        if (!!result) {
            let resultSplitted = result?.text.split(',');
            if(resultSplitted.length == 2){
                props.getSerialNumber(resultSplitted[1])
            }
        }

        if (!!error) {
            console.log(error);
        }
    }

    return (<>
        <div className="ui one cards">
            <div className="card black-background" >
                <div style={{
                    margin: 'auto',
                    width: '400px'
                }}>
                    <QrReader
                        scanDelay={300}
                        onResult={handleQrReader}
                        constraints={{ aspectRatio: { ideal: 1 } }}
                        ViewFinder={ViewFinder}
                        videoId="1"
                    />
                    <p className="qr-scanner-text">Scan the QR Code sticker on back of the red dial </p>
                </div>
            </div>
        </div>

    </>);
}