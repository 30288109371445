export class ChangeMowerTitleCmdInput {
  customerId: string;
  mowerId: string;
  mowerNewTitle: string;
  constructor(customerId: string, mowerId: string, mowerNewTitle: string) {
    this.customerId = customerId;
    this.mowerId = mowerId;
    this.mowerNewTitle = mowerNewTitle;
  }
}

export interface ChangeMowerTitleCmdOutput {
  done: boolean;
}

export class ChangeMowerTitleCmdOutput implements ChangeMowerTitleCmdOutput {
  constructor(init?: ChangeMowerTitleCmdOutput) {
    if (init !== undefined) {
      Object.assign(this, init);
    }
  }
}
