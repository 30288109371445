import { MowerActivity } from "./mower-activity";

export class MowersSummaryQuery implements MowersSummaryQuery {
  customerId: string;
  constructor(customerId: string) {
    this.customerId = customerId;
  }
}

export interface MowersSummaryResponse {
  totalMowers: number;
  activeMowers: number;
  hoursSpentMowing: number;
  averageCutTime: number;
  mowerSummaries: MowerActivity[];
}

export class MowersSummaryResponse implements MowersSummaryResponse {
  constructor(init?: MowersSummaryResponse) {
    if (init !== undefined) {
      Object.assign(this, init);
    }
  }
}
