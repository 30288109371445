import { Grid } from "semantic-ui-react";
import { toHoursAndMinutes } from "../../shared/utils";

export const MowerActivityStats = (props: any) => {
  if (
    props.mowerActivities!["totalLocationsVisited"] === undefined ||
    props.mowerActivities?.totalLocationsVisited === 0
  ) {
    return <></>;
  }

  return (
    <div className="mower-stats">
      <Grid columns="equal" divided>
        <Grid.Row textAlign="center">
          <Grid.Column className="mower-stat-item">
            <label className="mower-stat-value">
              {props.mowerActivities?.totalLocationsVisited}
            </label>{" "}
            <br />
            <label className="mower-stat-txt">
              Locations <span className="br" /> Visited
            </label>
          </Grid.Column>
          <Grid.Column className="mower-stat-item">
            <label className="mower-stat-value">
              {toHoursAndMinutes(
                props.mowerActivities?.averageCutTime?.toString()
              )}
            </label>
            <br />
            <label className="mower-stat-txt">
              Average Cut <span className="br" />
              Time Hours
            </label>
          </Grid.Column>
          <Grid.Column className="mower-stat-item">
            <label className="mower-stat-value">
              {toHoursAndMinutes(
                props.mowerActivities?.totalCutTime?.toString()
              )}
            </label>
            <br />
            <label className="mower-stat-txt">
              Total Cut <span className="br" />
              Time Hours
            </label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
