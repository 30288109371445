import { observer } from "mobx-react-lite";
import { QrReader } from "react-qr-reader";
import { ViewFinder } from "./ViewFinder";
import { useEffect, useState } from "react";
import { Button, Grid, GridColumn, Input, Label } from "semantic-ui-react";
import TextInput from "../../app/common/form/text-input";
import { QrScan } from "./QrScan";
import { MowerRegistration } from "../../types/registration/mowerRegistration";

const QrScanDetails = (props: any) => {
    const [serialNumber, setSerialNumber] = useState("");
    const [mowerName, setMowerName] = useState("");
    const [isValid, setValidData] = useState(false);
    const [mower, setMower] = useState<MowerRegistration>(new MowerRegistration());
    
    useEffect(() => {
        if(props.mower != undefined){
            let mowerDetails = props.mower as MowerRegistration;
            setMower(mowerDetails);
            setMowerName(props.mower.mowerName);
            setSerialNumber(props.mower.serialNumber);
            setValidData(true);
        } else {
            mower.isQrScan = true;
        }
    });

    const getSerialNumber = (serialNumber: any) => {
        setSerialNumber(serialNumber);
        setMowerName(serialNumber);
        setValidData(true);
        mower.mowerName = serialNumber;
        mower.serialNumber = serialNumber;
        props.onChangeMowerDetails(mower);
    }

    const handleOnChange =(e: any) =>{
        mower.mowerName = e.target.value;
        props.onChangeMowerDetails(mower);
    }

    const handleOnQrScanClose = () =>{
        mower.mowerName = "";
        mower.serialNumber = "";
        props.onChangeMowerDetails(mower);
        props.onQrScanClose(false);
    }

    return (<>
        {isValid ? (
            <><div className="oso-form">
                <Grid columns={2}>
                    <GridColumn style={{ width: "20%" }}>
                        <img className="ui small image" src="/assets/images/mower.png" />
                    </GridColumn>
                    <GridColumn style={{ width: "80%" }}>
                        <Grid columns={4} className="mower-details-grid-header">
                            <GridColumn style={{width: "30%"}}>
                                Mower Name
                            </GridColumn>
                            <GridColumn style={{width: "30%"}}>
                                Product Name
                            </GridColumn>
                            <GridColumn style={{width: "30%"}}>
                                Serial Number
                            </GridColumn>
                            <GridColumn style={{width: "10%"}}>

                            </GridColumn>
                        </Grid>
                        <Grid columns={4} className="mower-details-row">
                            <GridColumn style={{width: "30%"}}>
                                <Input
                                    name="mowerName"
                                    maxLength={50}
                                    defaultValue={mowerName}
                                    onChange={handleOnChange}
                                    className="react-datepicker-wrapper"
                                />
                            </GridColumn>
                            <GridColumn style={{width: "30%", marginTop: "10px" }}>
                                21 Inch Comercial Mower
                            </GridColumn>
                            <GridColumn style={{width: "30%", marginTop: "10px" }}>
                                {serialNumber}
                            </GridColumn>
                            <GridColumn style={{width: "10%"}}>
                                <Button circular icon='close' onClick={handleOnQrScanClose} floated="right" />
                            </GridColumn>
                        </Grid>
                    </GridColumn>
                </Grid>

            </div></>
        ) : (
            <><QrScan getSerialNumber={getSerialNumber} /></>
        )}
    </>);
}

export default observer(QrScanDetails); 