import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../rootStore";
import { AsyncState } from "../../types/common/AsyncState"
import { MowerView } from "../../types/mower/mower-view";

interface mowerState extends AsyncState {
  mower?: MowerView | null;
}

const initialState: mowerState = {
  mower: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
};

export const mowerSlice = createSlice({
  name: "mower",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
    },
    addMower: (state, action) => {
        state.isError = false;
        state.isSuccess = true;
        state.isLoading = false;
        state.mower = action.payload;
    },
    removeMower: (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.mower = null;
    }
  },
});


export const {addMower, removeMower} = mowerSlice.actions;
export const selecteMower = (state: RootState) => {
  return state.mower;
};
export default mowerSlice.reducer;
