import { useEffect, useState } from "react";
import { Button, Grid, GridColumn, Input } from "semantic-ui-react";
import { MowerRegistration } from "../../types/registration/mowerRegistration";

export const MowerDetailsEnter = (props: any) => {
    const [mowerName, setMowerName] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [mower, setMower] = useState<MowerRegistration>(new MowerRegistration());
    useEffect(() => {
        if(props.mower != undefined){
            let mowerDetails = props.mower as MowerRegistration;
            setMower(mowerDetails);
            setMowerName(props.mower.mowerName);
            setSerialNumber(props.mower.serialNumber);
        }
    });

    const handleOnChangeMowerName = (e: any) => {
        setMowerName(e.target.value);
        mower.mowerName = e.target.value;
        props.onChangeMowerDetails(mower);
    }

    const handleOnChangeSerialNumber = (e: any) => {
        setSerialNumber(e.target.value);
        mower.serialNumber = e.target.value;
        mower.isQrScan = false;
        props.onChangeMowerDetails(mower);
    }

    const handleOnClickClear = () => {
        setMowerName("");
        setSerialNumber("");
        mower.mowerName = "";
        mower.serialNumber = "";
        props.onChangeMowerDetails("", "");
    }

    return (
        <>
            <div className="ui cards pd-30" >
                <div className="card" style={{ width: "100%", padding: "30px" }}>
                    <div className="oso-form">
                        <p className="mower-details-header">Enter the Mower Details</p>
                        <Grid columns={1}>
                            <GridColumn>
                                <Grid columns={4} className="mower-details-grid-header">
                                    <GridColumn className="mower-details-grid-header-colum">
                                        Mower Name
                                    </GridColumn>
                                    <GridColumn className="mower-details-grid-header-colum">
                                        Product Name
                                    </GridColumn>
                                    <GridColumn className="mower-details-grid-header-colum">
                                        Serial Number
                                    </GridColumn>
                                    <GridColumn>

                                    </GridColumn>
                                </Grid>
                                <Grid columns={4} className="mower-details-row">
                                    <GridColumn className="mower-details-row-column">
                                        <Input
                                            name="mowerName"
                                            maxLength={50}
                                            value={mowerName}
                                            onChange={handleOnChangeMowerName}
                                            className="react-datepicker-wrapper"
                                        />
                                    </GridColumn>
                                    <GridColumn className="mower-details-row-column">
                                        <Input
                                            name="model"
                                            maxLength={50}
                                            value="21 Inch Comercial Mower"
                                            className="react-datepicker-wrapper"
                                        />
                                    </GridColumn>
                                    <GridColumn className="mower-details-row-column">
                                        <Input
                                            name="serialNumber"
                                            maxLength={50}
                                            value={serialNumber}
                                            onChange={handleOnChangeSerialNumber}
                                            className="react-datepicker-wrapper"
                                        />
                                    </GridColumn>
                                    <GridColumn style={{width: "10%", marginTop:"-50px"}}>
                                        <Button circular icon='close' onClick={handleOnClickClear} floated="right" />
                                    </GridColumn>
                                </Grid>
                            </GridColumn>
                        </Grid>

                    </div>
                </div>
            </div>
        </>
    );
}