import { useAuth0 } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Divider,
  Icon,
  Input,
  Grid,
  GridColumn,
  Popup,
} from "semantic-ui-react";
import { logger } from "../../app/common/logger";
import { useDocumentTitle } from "../../app/common/page-title";
import {
  CLAIM_OSO_APP_COMPANY_ID,
  CLAIM_OSO_APP_USER_ID,
} from "../../types/constants";
import { useStore } from "../../app/stores/store";
import { getCustomerId } from "../../shared/utils";
import { ChangeMowerTitleCmdInput } from "../../types/mower/update-mower-title-models";
import { toast } from "react-toastify";
import MowerStatusIcon from "../icons/MowerStatusIcon";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { LatLngExpression } from "leaflet";
import { MowerView } from "../../types/mower/mower-view";
import { MapMarkerProps } from "../map/MapMarkers";
import { MapWithMarkers } from "../map/MapWithMarkers";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { addMower, removeMower } from "../../store/mower/mowerSlice";
import cloneDeep from "lodash/cloneDeep";

const MowerDetails = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } =
    useAuth0();
  const { mowerStore } = useStore();
  const { updateMowerTitle } = mowerStore;
  const [headerMowerTitle, setHeaderMowerTitle] = useState("");
  const [mowerTitle, setMowerTitle] = useState<string | null>("");
  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [mowerTitleError, setMowerTitleError] = useState(false);
  const [mowerTitleSaved, setMowerTitleSaved] = useState(false);

  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;

  const navigate = useNavigate();

  useDocumentTitle("Mowers");

  const [mower, setMower] = useState<MowerView>();
  const [markers, setMarkers] = useState<MapMarkerProps[]>([]);
  const [highlightedLocationIndex, SetHighlightedLocationIndex] = useState(-1);
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.mower);

  useEffect(() => {
    if (isAuthenticated) {
      if (
        user![CLAIM_OSO_APP_USER_ID] === undefined ||
        user![CLAIM_OSO_APP_COMPANY_ID] === undefined
      ) {
        navigate("/");
        return;
      }

      if (selector.isSuccess && selector.mower) {
        const mow = cloneDeep(selector.mower);
        mow.mowerTitle =
          mow.mowerTitle == null ? mow.serialNumber : mow.mowerTitle;
        setMower(mow);
        if (mow && mow.mowerTitle && mow.mowerTitle.length > 0) {
          setMowerTitle(mow?.mowerTitle);
          setHeaderMowerTitle(mow?.mowerTitle);
        } else {
          setMowerTitle(mow?.serialNumber ?? null);
          setHeaderMowerTitle(mow?.serialNumber ?? null);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (mower) {
      const marker: MapMarkerProps = {
        color: mower.activityColor,
        iconLetter: (mower.mowerName ?? mower.mowerTitle).charAt(0),
        latitude: mower.latitude,
        longitude: mower.longitude,
      };
      setMarkers([marker]);
    }
  }, [mower]);

  useEffect(() => {
    if (mower && mower.mowerTitle && mower.mowerTitle.length > 1) {
      setMowerTitle(mower?.mowerTitle ?? null);
    }
  }, [mower]);

  const RenderHeading = () => {
    return isLoading || !isAuthenticated ? (
      <></>
    ) : (
      <>
        <div className="page-title">
          <Icon
            size="small"
            color="black"
            link
            name="arrow left"
            onClick={() => navigate("/mowers")}
            className="no-border"
          />
          {headerMowerTitle}
        </div>
        <br />
        <Divider section />
      </>
    );
  };

  const SaveMowerTitle = () => {
    if (mower === undefined || submitting) {
      return;
    }

    if (!mower.mowerTitle || mower.mowerTitle.length < 1) {
      toast.error(
        "Mower name cannot be empty. Please enter a valid name for the mower",
        { theme: "colored" }
      );
      return;
    }

    if (mower.mowerTitle && mower.mowerTitle.length > 25) {
      toast.error("Mower name cannot be greater than 25 characters long.", {
        theme: "colored",
      });
      return;
    }

    if (mower) {
      setSubmitting(true);
      setErrorMsg("");
      const controller = new AbortController();
      let customerId = getCustomerId(user![CLAIM_OSO_APP_COMPANY_ID]);
      const input = new ChangeMowerTitleCmdInput(
        customerId,
        mower?.mowerId,
        mower?.mowerTitle
      );
      setMowerTitle(mower?.mowerTitle);
      updateMowerTitle(input, controller.signal)
        .then(function (res) {
          toast.success("Success!", { theme: "colored" });
          setSubmitting(false);
          setErrorMsg("");
          //localStorage.removeItem(LOCAL_STORAGE_SEL_MOWER);
          dispatch(removeMower());
          const newMower = { ...mower };
          setMowerTitle(newMower.mowerTitle);
          setHeaderMowerTitle(newMower.mowerTitle);
          setMowerTitleSaved(true);
          setMower(newMower);
        })
        .catch((err: any) => {
          logger.error(err);
          logger.error("Please verify API is up and running.");
          logger.log(err?.response);
          if (err?.response?.data?.message) {
            setErrorMsg(err?.response?.data?.message);
          }
        });
      return () => {
        controller.abort();
      };
    }
  };

  const ChangeTitle = (e: any, data: any) => {
    if (mower) {
      mower.mowerTitle = data.value;
      setMower(mower);
      if (mower.mowerTitle.length > 25) {
        setMowerTitleError(true);
      } else if (mowerTitleError) {
        setMowerTitleError(false);
      }
    }
  };

  const handleMowerNameClear = () => {
    if (mower) {
      const newMower = { ...mower };
      newMower.mowerTitle = "";
      setMowerTitleError(false);
      setMowerTitle("");
      setMower(newMower);
    }
  };

  const RenderMower = (props: any) => {
    return mower === undefined || mower!["mowerTitle"] === undefined ? (
      <></>
    ) : (
      <>
        <div className="profile-label">Mower Name</div>
        <Input
          type="text"
          id="mower-title"
          name="mower-title"
          maxLength={30}
          size="large"
          defaultValue={mowerTitle}
          onChange={ChangeTitle}
          required
          className="mower-title-edit"
          fluid
          icon={<Icon name="delete" link onClick={handleMowerNameClear} />}
          error={mowerTitleError}
        ></Input>
        <br />
        <div className={"profile-label"}>Status</div>
        <div className="profile-value">
          <div>
            <MowerStatusIcon statusText={mower?.lastBladeStatus} />
            {mower.lastBladeStatus} at {mower.location}
          </div>
        </div>

        <br />
        <div className="profile-label">Serial Number</div>
        <div className="profile-value">{mower?.serialNumber}</div>
        <br />
        <div className="profile-label">Model</div>
        <div className="profile-value">
          {mower?.model && mower.model.length > 0
            ? mower.model
            : '21" Lawn Mower'}
        </div>
        <br />
        <br />
        <br />
        <Button fluid className="mower-save" onClick={() => SaveMowerTitle()}>
          SAVE
        </Button>
        <br />
        <label className="error">{errorMsg}</label>
      </>
    );
  };

  const position = [51.505, -0.09] as LatLngExpression;
  const DesktopMowerView = () => {
    return (
      <>
        {isLoading || !isAuthenticated ? (
          <></>
        ) : (
          <>
            <Grid
              columns={2}
              style={{
                height: "100%",
                width: "100%",
                paddingRight: "0px",
                margin: "0px",
              }}
            >
              <Grid.Column style={{ maxWidth: "425px", height: "100vh" }}>
                <div style={{ paddingTop: "6px" }}></div>
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    maxWidth: "400px",
                    height: "100vh",
                  }}
                >
                  <RenderHeading />
                  <br />
                  <RenderMower textAlign="left" />
                </div>
              </Grid.Column>
              <Grid.Column
                style={{ width: "100%", height: "100vh", flex: "1" }}
              >
                <MapWithMarkers
                  markers={markers}
                  SetHighlightedLocationIndex={SetHighlightedLocationIndex}
                  localStorageMapBound=""
                />
              </Grid.Column>
            </Grid>
          </>
        )}
      </>
    );
  };

  const MobileMowerView = () => {
    return (
      <>
        {isLoading || !isAuthenticated ? (
          <></>
        ) : (
          <>
            <div style={{ paddingTop: "6px" }}></div>

            <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <RenderHeading />
              <br />
              <RenderMower />
            </div>
          </>
        )}
      </>
    );
  };

  const MowerView = () => {
    if (isMobile) {
      return <MobileMowerView />;
    }
    return <DesktopMowerView />;
  };

  return <MowerView />;
};

export default observer(MowerDetails);
