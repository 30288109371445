export class AcceptTermsAndConditionsCmdInput {
  userId: number;
  constructor(userId: number) {
    this.userId = userId;
  }
}

export interface AcceptTermsAndConditionsCmdOutput {
  done: boolean;
}

export class AcceptTermsAndConditionsCmdOutput implements AcceptTermsAndConditionsCmdOutput {
  constructor(init?: AcceptTermsAndConditionsCmdOutput) {
    if (init !== undefined) {
      Object.assign(this, init);
    }
  }
}
