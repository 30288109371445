import { MapContainer, TileLayer, useMap } from "react-leaflet";
import MapMarkers, { MapMarkerProps } from "./MapMarkers";
import ZoomControlWithReset from "../../app/common/map-zoom";
import {
  DEFAULT_MAP_LATITUDE,
  DEFAULT_MAP_LONGITUDE,
  LOCAL_STORAGE_DEFAULT_MAP_BOUNDS,
} from "../../types/constants";
import { LatLngBoundsExpression } from "leaflet";
import { Dispatch, SetStateAction } from "react";
import { number } from "yargs";

interface LatituteLongitute{
  lat: number;
  lng: number;
}

interface MowersMapProps {
  markers: MapMarkerProps[];
  localStorageMapBound: string;
  SetHighlightedLocationIndex: Dispatch<SetStateAction<number>>;
}

interface MapBounds {
  markersPresent: boolean;
  bounds: LatLngBoundsExpression;
  SetHighlightedLocationIndex: Dispatch<SetStateAction<number>>;
  localStorageMapBound: string;
}

const FitAllMowerBounds = ({
  markersPresent,
  bounds,
  SetHighlightedLocationIndex,
  localStorageMapBound,
}: MapBounds) => {
  const map = useMap();
  if (markersPresent ) {
    map.fitBounds(bounds);
  }

  map.scrollWheelZoom.disable();
  map.on("focus", () => {
    map.scrollWheelZoom.enable();
  });
  map.on("blur", () => {
    map.scrollWheelZoom.disable();
  });
  map.on("click", () => {
    SetHighlightedLocationIndex(-1);
  });
  map.on('moveend', function(e) {
    if(localStorageMapBound){
      var bounds = map.getBounds();
      var northEast = bounds.getNorthEast();
      var southWest = bounds.getSouthWest();
      
      if(localStorage.getItem(LOCAL_STORAGE_DEFAULT_MAP_BOUNDS)){
        localStorage.removeItem(LOCAL_STORAGE_DEFAULT_MAP_BOUNDS);
      } else{
        localStorage.setItem(
          localStorageMapBound,
          JSON.stringify({ northEast:  northEast, southWest: southWest})
        );
      }
    }
    
 });

  return <></>;
};

const highlightMowerLocation = (
  numOfMowers: number,
  index: number,
  SetHighlightedLocationIndex: any
) => {
  if (numOfMowers > index) {
    SetHighlightedLocationIndex(index);

  }
};

export const MapWithMarkers = (props: MowersMapProps) => {
  var bounds: LatLngBoundsExpression = [];
  var defaultBounds: LatLngBoundsExpression = [];
  for (let marker of props.markers) {
    defaultBounds.push([marker.latitude, marker.longitude]);
  }
  var locaStorageBounds = props.localStorageMapBound ? localStorage.getItem(props.localStorageMapBound) : null;
  if(locaStorageBounds){
    var json = JSON.parse(locaStorageBounds)
      let northEast: LatituteLongitute = json["northEast"];
      let southWest: LatituteLongitute = json["southWest"];
      
      bounds.push([northEast.lat, northEast.lng]);
      bounds.push([southWest.lat, southWest.lng]);
  } else {
    bounds=defaultBounds;
    localStorage.setItem(
      LOCAL_STORAGE_DEFAULT_MAP_BOUNDS,
      JSON.stringify({ defaultMap:  "1"})
    );
    
  }
  
  return (
    <MapContainer
      style={{
        width: "100%",
        paddingTop: "0px",
        height: "100%",
        minHeight: "40vh",
        zIndex: "0",
      }}
      center={[DEFAULT_MAP_LATITUDE, DEFAULT_MAP_LONGITUDE]}
      zoom={13}
      scrollWheelZoom={false}
      zoomControl={false}
    >
      <FitAllMowerBounds
        markersPresent={props.markers && props.markers.length > 0}
        bounds={bounds}
        SetHighlightedLocationIndex={props.SetHighlightedLocationIndex}
        localStorageMapBound={props.localStorageMapBound}
      />
      <ZoomControlWithReset 
        bounds={defaultBounds}
        localStorageMapBound={props.localStorageMapBound}
      />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
          OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MapMarkers
        markers={props.markers}
        showLabels={true}
        SetHighlightedLocationIndex={props.SetHighlightedLocationIndex}
        callback={highlightMowerLocation}
      />
    </MapContainer>
  );
};
