import { Grid } from "semantic-ui-react";
import { toHoursAndMinutes } from "../../shared/utils";

interface MowersStatsProps {
  activeMowersCount: number;
  totalMowersCount: number;
  totalHoursSpentMowing: number;
  averageCutTime: number;
  user: any
}

const MowersStats = (props: MowersStatsProps) => {
  return (
    <div className="mower-stats">
      <Grid columns="equal" divided>
        <Grid.Row textAlign="center">
          <Grid.Column className="mower-stat-item">
            <label className="mower-stat-value">
              {props.user?.isSubscribed ? (<>{props.activeMowersCount}</>) : ("-")}
            </label>{" "}
            <br />
            <label className="mower-stat-txt">Active<span className="br" />Mowers</label>
          </Grid.Column>
          <Grid.Column className="mower-stat-item">
            <label className="mower-stat-value">
            {props.user?.isSubscribed ? (<>{toHoursAndMinutes(props.averageCutTime.toString())}</>) : ("-")}
            </label>
            <br />
            <label className="mower-stat-txt">Average Cut<span className="br" />Time Hours</label>
          </Grid.Column>
          <Grid.Column className="mower-stat-item">
            <label className="mower-stat-value">
              {props.user?.isSubscribed ? (<>{toHoursAndMinutes(props.totalHoursSpentMowing.toString())}</>) : ("-")}
            </label>
            <br />
            <label className="mower-stat-txt">Total Cut<span className="br" />Time Hours</label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default MowersStats;
