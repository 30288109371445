import { useAuth0 } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { List, Button, Divider, Icon, Popup, Grid } from "semantic-ui-react";
import { logger } from "../../app/common/logger";
import { useDocumentTitle } from "../../app/common/page-title";
import {
  CLAIM_OSO_APP_COMPANY_ID,
  CLAIM_OSO_APP_USER_ID,
} from "../../types/constants";
import { useStore } from "../../app/stores/store";
import MowerStatusIcon from "../icons/MowerStatusIcon";
import { MowerView } from "../../types/mower/mower-view";
import {
  MowerListQryInput,
  MowerListQryOutput,
} from "../../types/mower/mower-list-query";
import { getCustomerId } from "../../shared/utils";
import Loading from "../../app/layout/loading";
import { MapMarkerProps } from "../map/MapMarkers";
import { MapWithMarkers } from "../map/MapWithMarkers";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { addMower, removeMower } from "../../store/mower/mowerSlice";
import { Exception } from "../Exception";

const MowersList = () => {
  const { isAuthenticated, isLoading, user } = useAuth0();

  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;

  const navigate = useNavigate();

  useDocumentTitle("Mowers");

  const { mowerStore } = useStore();
  const { getAllMowerList } = mowerStore;
  const [mowers, setMowers] = useState(new MowerListQryOutput());
  const [isMowerDataLoading, setIsMowerDataLoading] = useState(true);
  const [mapMarkers, setMapMarkers] = useState<MapMarkerProps[]>([]);
  const [highlightedLocationIndex, SetHighlightedLocationIndex] = useState(-1);
  const [fetchError, setFetchError] = useState("");
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.mower);

  useEffect(() => {
    if (isAuthenticated) {
      if (
        user![CLAIM_OSO_APP_USER_ID] === undefined ||
        user![CLAIM_OSO_APP_COMPANY_ID] === undefined
      ) {
        navigate("/");
        return;
      }

      dispatch(removeMower());
      //localStorage.removeItem(LOCAL_STORAGE_SEL_MOWER);
      setFetchError("");
      const controller = new AbortController();
      let customerId = getCustomerId(user![CLAIM_OSO_APP_COMPANY_ID])

      const input = new MowerListQryInput(customerId);
      getAllMowerList(input, controller.signal)
        .then(function (res) {
          setMowers(res);
          setIsMowerDataLoading(false);
          if (res?.mowerList?.length > 0) {
            const markers: MapMarkerProps[] = [];
            for (const mower of res.mowerList) {
              var markerColor = "grey";
              if (mower.lastBladeStatus.trim().toLowerCase() === "active") {
                markerColor = "green";
              } else if (mower.lastBladeStatus.trim().toLowerCase() === "on") {
                markerColor = "blue";
              }
              let mowerTitle = mower.mowerTitle;
              if (!mower.mowerTitle) {
                mowerTitle = mower.mowerName;
              }
              const marker: MapMarkerProps = {
                color: markerColor,
                iconLetter: mowerTitle.charAt(0),
                latitude: mower.latitude,
                longitude: mower.longitude,
              };
              markers.push(marker);
            }
            setMapMarkers(markers);
          }
        })
        .catch((err) => {
          logger.error("Please verify API is up and running.", err);
          setFetchError(err.message);
        });
      return () => {
        controller.abort();
      };
    }
  }, []);

  const RenderHeading = () => {
    if (isLoading || !isAuthenticated) {
      return <></>;
    }

    return (
      <>
        <div className="page-title">
          <Icon
            size="small"
            color="black"
            link
            name="arrow left"
            onClick={() => navigate("/")}
            className="no-border"
          />
          &nbsp;Mowers
        </div>
        <br />
        <Divider section />
      </>
    );
  };

  const selectMower = (mowerId: string) => {
    const mower = mowers?.mowerList?.filter((x) => x.mowerId === mowerId)![0];
    if (mower) {
      if (selector.isSuccess && selector.mower) {
      } else {
        dispatch(addMower(mower));
      }
      //localStorage.setItem(LOCAL_STORAGE_SEL_MOWER, JSON.stringify(mower));
      navigate("/mower");
    }
  };

  const RenderMowerList = (props: any) => {

    if (fetchError) {
      return (
        <>
          <Exception
            error={fetchError}
          />
        </>
      );
    }

    if (mowers.mowerList?.length === 0) {
      return <>
        <div className="ui">
          <img className="ui centered circular medium image" src="/assets/images/Oval.svg" />
          <img className="ui  small image" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} src="/assets/images/mower-transparent.png" />
          <br />
          <div style={{ textAlign: "center" }}>You currently have no mowers.</div>\
          <br />
          <div className="vertical-center">
            <Button className="ui black-bg-color" onClick={() => navigate("/add-mower")}>Add Your Mowers</Button>
          </div>
        </div>
      </>;
    }

    return (
      <>
        <div className="mower-list-count">
          {mowers?.mowerList?.length}
          {mowers?.mowerList?.length > 1 ? " Mowers" : " Mower"}{" "}
        </div>
        {isMowerDataLoading ? <Loading content="Loading Mowers..." /> : <></>}

        <List relaxed style={{ paddingRight: props.paddingRight }}>
          {mowers?.mowerList?.map((mower: MowerView, index: number) => {
            return (
              <React.Fragment key={mower.mowerId}>
                <List.Item className="mower-list-item">
                  <List.Icon size="large" verticalAlign="top">
                    <br />
                    <MowerStatusIcon statusText={mower?.lastBladeStatus} />
                  </List.Icon>

                  <List.Content>
                    <List.Description className="pt-s mower-list-item-desc">
                      <div className="mower-list-item-title">
                        <a href="#" onClick={() => selectMower(mower.mowerId)}>
                          {mower.mowerTitle && mower.mowerTitle.length > 0
                            ? mower.mowerTitle
                            : mower.serialNumber}
                          {/* mower.mowerTitle ?? mower.mowerName */}
                        </a>
                      </div>
                      {mower.lastBladeStatus}{" "}
                      {mower.lastBladeStatus.trim().toLowerCase() !==
                        "offline" ? (
                        <span> at {mower.location} </span>
                      ) : (
                        ""
                      )}
                      <br />
                    </List.Description>
                  </List.Content>
                </List.Item>
              </React.Fragment>
            );
          })}
        </List>
        <div className="top-btn" id="topbtn">
          <Button
            color="black"
            icon="plus"
            circular
            onClick={() => navigate("/add-mower")}
          ></Button>
        </div>

      </>
    );
  };

  const DesktopMowerListView = () => {
    return (
      <>
        {isLoading || !isAuthenticated ? (
          <></>
        ) : (
          <>
            <Grid
              columns={2}
              style={{
                height: "100%",
                width: "100%",
                paddingRight: "0px",
                margin: "0px",
              }}
            >
              <Grid.Column
                style={{
                  maxWidth: "425px",
                  height: "100vh",
                  overflowY: "scroll",
                  overflowX: "hidden"
                }}
              >
                <div style={{ paddingTop: "6px" }}></div>
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    maxWidth: "400px",
                  }}
                >
                  <RenderHeading />
                  <br />
                  <RenderMowerList textAlign="left" />
                </div>
              </Grid.Column>
              <Grid.Column
                style={{ width: "100%", height: "100vh", flex: "1" }}
              >
                {" "}
                <MapWithMarkers
                  markers={mapMarkers}
                  SetHighlightedLocationIndex={SetHighlightedLocationIndex}
                  localStorageMapBound=""
                />
              </Grid.Column>
            </Grid>
          </>
        )}
      </>
    );
  };

  const MobileMowerListView = () => {
    return (
      <>
        {isLoading || !isAuthenticated ? (
          <></>
        ) : (
          <>
            <div style={{ paddingTop: "6px" }}></div>

            <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <RenderHeading />
              <br />
              <RenderMowerList />
            </div>
          </>
        )}
      </>
    );
  };

  const MowerListView = () => {
    if (isMobile) {
      return <MobileMowerListView />;
    }
    return <DesktopMowerListView />;
  };

  return <MowerListView />;
};

export default observer(MowersList);
