import { Button } from "semantic-ui-react";

export const GoToTop =() =>{
    const goToTopBtn = () => {
        document.getElementById("mowerActivityColumn")?.scrollTo(0, 0);
    }
    return(
        <div className="top-btn" id="topbtn" onClick={goToTopBtn}>
            <Button circular icon='chevron up' />
        </div>
    );
}
