import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { AxiosInterceptorProvider } from "../api/axios-interceptor";
import { ConfigContext } from "../../useConfig";

const AuthProvider = ({ children }: any) => {
  const { config } = useContext(ConfigContext);

  const domain = config.auth0Domain;
  const clientId = config.auth0ClientId;
  const audience = config.auth0Audience;

  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.redirectTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      cacheLocation="localstorage"
    >
      <AxiosInterceptorProvider>{children}</AxiosInterceptorProvider>
    </Auth0Provider>
  );
};

export default AuthProvider;
